import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Avatar, Stack, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(fname, lname) {
  return {
    sx: {
      bgcolor: stringToColor(`${fname} ${lname}`),
      width: 52,
      height: 52,
    },
    children: `${fname[0]}${lname[0]}`,
  }
}

export default function AgentCard({ agent }) {
  return (
    <Box sx={{ minWidth: 275, opacity: agent?.isActive ? 1 : 0.3 }}>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Avatar
              {...stringAvatar(agent?.f_name, agent?.l_name)}
              src={agent?.avatar?.url}
            />
            <Stack>
              <Tooltip title={agent?.fname} placement="top">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {agent?.fname} {!agent?.isActive && ` - Disabled`}
                </Typography>
              </Tooltip>
              <Typography color="text.secondary">{agent?.email}</Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Link
              to={`/users/profile/${agent?._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              View Profile
            </Link>
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}
