import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { formatDate, formatPrice } from "../../helppers/formatters"
import { Link } from "react-router-dom"
import { Divider, Popover, Stack, Typography } from "@mui/material"
import MouseOverPopover from "../global/PopOverHover"

function Row({ lead }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Link
            style={{
              color: "inherit",
              fontWeight: 600,
              textDecoration: "none",
            }}
            to={`/leads/item/${lead?._id}`}
          >
            {lead?.title}
          </Link>
        </TableCell>
        <TableCell align="left">{lead.vrm}</TableCell>
        <TableCell align="left">
          {lead?.countredBy?.map((d, i) =>
            i === 0 ? d?.dname : ` - ${d?.dname}`
          )}
        </TableCell>
        <TableCell align="center">{formatDate(lead.counteredAt)}</TableCell>
        <TableCell align="center">{formatPrice(lead.prePrice)}</TableCell>
        <TableCell align="center">
          {formatPrice(lead.lastCounter?.amount)}
        </TableCell>
        <TableCell align="center">
          <MouseOverPopover
            value={formatPrice(
              lead.countersIn ? lead.countersIn[0]?.amount : undefined
            )}
            data={
              <Stack spacing={1} maxWidth="400px" p={2}>
                {lead.countersIn &&
                  lead.countersIn?.map((c, i) => (
                    <>
                      <Stack
                        key={c?._id + i}
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {
                            lead?.countredBy?.find(
                              (d) => d?._id === c?.dealership
                            )?.dname
                          }
                        </Typography>
                        <Typography variant="body1" fontWeight={600}>
                          {formatPrice(c?.amount)}
                        </Typography>
                      </Stack>
                      {lead.countersIn?.length - 1 !== i && (
                        <Divider key={i + c?._id} />
                      )}
                    </>
                  ))}
              </Stack>
            }
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function CountersTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell>Title</TableCell>
            <TableCell align="left">REG</TableCell>
            <TableCell align="left">Dealerships</TableCell>
            <TableCell align="center">Last Bid</TableCell>
            <TableCell align="center">Pre Neg Price</TableCell>
            <TableCell align="center">Counter</TableCell>
            <TableCell align="center">Bids</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.map((lead) => <Row key={lead?._id} lead={lead} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
