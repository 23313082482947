import * as React from "react"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import TodayIcon from "@mui/icons-material/Today"
import Typography from "@mui/material/Typography"
import momentTZ from "moment-timezone"
import { ListItemButton } from "@mui/material"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import UpdateAppointment from "../../modals/UpdateAppointment"
import { useState } from "react"

const formatDate = (date, tz = "America/Los_Angeles") => {
  if (!date) return "N/A"
  return momentTZ.tz(date, tz).format("dddd MM/DD/YY @ h:mm A")
}

export default function AppointmentLine({ app, setAppointments, setActivity }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemIcon sx={{ minWidth: "max-content", px: 1 }}>
          <TodayIcon
            color={
              app?.status === "Pending"
                ? "info"
                : app?.status === "Deal"
                ? "success"
                : app?.status === "Lost"
                ? "error"
                : "inherit"
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={formatDate(app?.start, app?.tz)}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
                fontWeight={500}
              >
                {app?.dealership?.dname} - {app?.status}
              </Typography>
            </React.Fragment>
          }
        />
        {app?.status === "Pending" && (
          <ListItemButton
            onClick={() => setOpen(true)}
            sx={{ maxWidth: "max-content !important", px: 1 }}
          >
            <BorderColorIcon />
          </ListItemButton>
        )}
      </ListItem>
      {open && (
        <UpdateAppointment
          open={open}
          setOpen={setOpen}
          app={app}
          setActivity={setActivity}
          setAppointmnets={setAppointments}
        />
      )}
    </>
  )
}
