import * as React from "react"
import Popper from "@mui/material/Popper"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import ResCard from "./ResCard"
import { Button, Divider, Typography } from "@mui/material"

export default function PositionedPopper({
  anchorEl,
  open,
  handleClose,
  results,
  loading,
  err,
  isDone,
  setAnchorEl,
  setOpen,
}) {
  return (
    <Popper
      sx={{ width: "411px", zIndex: 9999 }}
      open={open}
      anchorEl={anchorEl}
      placement={"bottom"}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Stack
              spacing={1}
              sx={{ height: "100%", maxHeight: 500, overflowY: "auto" }}
            >
              <Stack spacing={1}>
                {!isDone ? (
                  <Typography
                    variant="body"
                    color="text.secondary"
                    component="div"
                    textAlign={"center"}
                    sx={{ p: 2 }}
                  >
                    Type to search
                  </Typography>
                ) : loading ? (
                  <Typography
                    variant="body"
                    color="text.secondary"
                    component="div"
                    textAlign={"center"}
                    sx={{ p: 2 }}
                  >
                    Searching ...
                  </Typography>
                ) : err ? (
                  <Typography
                    variant="body"
                    color="text.secondary"
                    component="div"
                    textAlign={"center"}
                    sx={{ p: 2 }}
                  >
                    {err}
                  </Typography>
                ) : results && results?.length > 0 ? (
                  <>
                    {results?.map((res, i) => (
                      <>
                        <ResCard
                          key={res?._id + i}
                          item={res}
                          setAnchorEl={setAnchorEl}
                          setOpen={setOpen}
                        />
                      </>
                    ))}
                  </>
                ) : (
                  results &&
                  results?.length === 0 && (
                    <Typography
                      variant="body"
                      color="text.secondary"
                      component="div"
                      textAlign={"center"}
                      sx={{ p: 2 }}
                    >
                      No Results found
                    </Typography>
                  )
                )}
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={"row"} pb={1} px={2} justifyContent="end">
              <Button onClick={handleClose}>Close</Button>
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
