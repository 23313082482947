import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import moment from "moment"
import CommentIcon from "@mui/icons-material/Comment"

export default function NoteView({ notes }) {
  return (
    <List dense={true}>
      {notes && notes?.length > 0 ? (
        <>
          {notes?.map((item, i) => (
            <>
              <ListItem>
                <ListItemIcon>
                  <CommentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      spacing={2}
                    >
                      <Typography fontWeight={600} variant="body2">
                        {item?.createdBy}
                      </Typography>
                      <Typography variant="body2" fontSize={13}>
                        {moment(item?.createdAt).format("MM/DD/YY")}
                      </Typography>
                    </Stack>
                  }
                  secondary={item?.comment}
                />
              </ListItem>
              {i !== notes?.length - 1 && <Divider />}
            </>
          ))}
        </>
      ) : (
        <>Notes not Available!</>
      )}
    </List>
  )
}
