import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBhw9k9m10lbT7P1eVwRKhXO928IwoA3uw",
  authDomain: "dealerbids-b80b2.firebaseapp.com",
  projectId: "dealerbids-b80b2",
  storageBucket: "dealerbids-b80b2.appspot.com",
  messagingSenderId: "140935733884",
  appId: "1:140935733884:web:0f64f5294793e2e4a75ff9",
  measurementId: "G-GSM1DTXZCM",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
