import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import React, { useState } from "react"
import SimpleModal from "../modals/SimpleModal"
import InstalledOptions from "../global/InstalledOptions"
import Chip from "@mui/material/Chip"
import { FaFacebook } from "react-icons/fa"
import ConditionReport from "../global/ConditionReport"
import AutoCheckData from "../global/AutoCheckData"

const imgStyle = { height: "1.75rem", width: "auto" }

export default function ExtraData({ data, lead }) {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState(null)

  const handelOpen = (name) => {
    setTitle(name)
    setOpen(true)
  }

  return (
    <>
      <Stack
        spacing={5}
        direction="row"
        sx={{ paddingX: "20px", paddingY: "10px" }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack spacing={5} direction="row">
          <Button onClick={() => handelOpen("AutoCheck")} variant="outlined">
            AutoCheck
          </Button>
          <Button
            onClick={() => handelOpen("Condition Report")}
            variant="contained"
            color="primary"
          >
            Condition Report
          </Button>
        </Stack>

        <Chip
          icon={<FaFacebook fontSize={20} color="#116ecb" />}
          label={lead?.fbName}
          variant="outlined"
          sx={{ px: 0.5, fontSize: 16, textTransform: "capitalize" }}
        />
      </Stack>
      {title && (
        <SimpleModal
          open={open}
          setOpen={setOpen}
          setTitle={setTitle}
          maxWidth={'sm'}
        >
          {title === "AutoCheck" ? (
            <>
              <AutoCheckData autoCheck={lead?.autoCheck} />
            </>
          ) : title === "Condition Report" ? (
            <>
              <ConditionReport html={lead?.condition} />
            </>
          ) : null}
        </SimpleModal>
      )}
    </>
  )
}
