import React, { useState } from "react"
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material"
import SelectMenu from "../components/createPage/SelectMenu"
import useData from "../context/Data"
import DealershipCriteria from "../components/dealership/DealershipCriteria"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const INITIAL_STATE = {
  dname: "",
  city: "",
  address: "",
  organisation: null,
  market: null,
  logo: null,
  postCode: "",
}

const C_INITIAL_STATE = {
  price: {
    minVal: 0,
    maxVal: 500000,
  },
  year: {
    minVal: 2000,
    maxVal: new Date().getFullYear() + 1,
  },
  mileage: {
    minVal: 0,
    maxVal: 200000,
  },
  titleStatus: [],
}

export default function CreateNewDealershipPage() {
  const { orgs, setDealerships } = useData()
  const navigate = useNavigate()

  const [dealership, setDealership] = useState(INITIAL_STATE)
  const [criteria, setCreiteria] = useState(C_INITIAL_STATE)
  const [sugTz, setSugTz] = useState(null)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)

  const handleChangeSelect = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }

  const saveDealership = async (e) => {
    e.preventDefault()
    const data = {
      ...dealership,
      criteria,
    }
    setLoading(true)
    try {
      const res = await axios.post(`${URL}/dealerships`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setLoading(false)
        setShow(true)
        setDealerships((prev) => [...prev, res.data.dealership])
        setDealership(INITIAL_STATE)
        setCreiteria(C_INITIAL_STATE)
        setSugTz(null)
        setTimeout(() => {
          setShow(false)
          navigate(`/dealerships/profile/${res.data.dealership?._id}`)
        }, 1000)
      }
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Container maxWidth="md">
        <Paper>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { my: 1, width: "100%" },
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
            noValidate
            autoComplete="off"
            onSubmit={saveDealership}
          >
            <Grid container spacing={3} columns={4} sx={{ px: 5, py: 2 }}>
              {sugTz && (
                <Grid item sm={4}>
                  <small>
                    Suggested Timezone: <strong>{sugTz?.tz}</strong>
                  </small>
                </Grid>
              )}
              <Grid item sm={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Dealership Name"
                  variant="outlined"
                  fullWidth
                  value={dealership?.dname}
                  onChange={(e) => onValueChange(e.target.value, "dname")}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="Organisation"
                  options={orgs}
                  value={dealership?.organisation}
                  handleChange={handleChangeSelect}
                  name={"organisation"}
                >
                  {orgs &&
                    orgs?.map((o, i) => (
                      <MenuItem key={o._id + i} value={o._id}>
                        {o.name}
                      </MenuItem>
                    ))}
                </SelectMenu>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Dealership Address"
                  variant="outlined"
                  fullWidth
                  value={dealership?.address}
                  onChange={(e) => onValueChange(e.target.value, "address")}
                />
              </Grid>
              <Grid item sm={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={dealership?.city}
                  onChange={(e) => onValueChange(e.target.value, "city")}
                />
              </Grid>
              <Grid item sm={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Post Code"
                  variant="outlined"
                  fullWidth
                  value={dealership?.postCode}
                  onChange={(e) => onValueChange(e.target.value, "postCode")}
                />
              </Grid>
            </Grid>
            {dealership?.dname &&
              dealership?.address &&
              dealership?.city &&
              dealership?.organisation &&
              dealership?.postCode && (
                <>
                  <Divider />
                  <DealershipCriteria
                    criteria={criteria}
                    setCreiteria={setCreiteria}
                  />
                  <Divider />
                  <Stack
                    direction={"row"}
                    justifyContent="end"
                    alignItems={"center"}
                    spacing={2}
                    px={2}
                    pb={2}
                  >
                    <Button type="submit" variant="contained">
                      Create
                    </Button>
                  </Stack>
                </>
              )}
          </Box>
        </Paper>
      </Container>
      <FeedBackBox loading={loading} show={show} err={err} />
    </>
  )
}
