import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React from "react"
import { useMemo } from "react"
import { useEffect } from "react"
import useAuth from "../../context/Auth"
import { saveProfileImage } from "../../helppers/saveProfile"
import ProfilePicture from "./ProfilePicture"

const INITIAL = {
  email: "",
  f_name: "",
  l_name: "",
  isOwner: false,
  slackId: "",
  role: [],
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function EditProfileSection() {
  const { user, setUser } = useAuth()
  const [agent, setAgent] = React.useState(INITIAL)
  const [loading, setLoading] = React.useState(false)
  const [err, setErr] = React.useState(null)

  const isAllowed = useMemo(() => {
    if (!user) return false
    if (!user.role?.length === 0) return false
    const roles = user?.role?.filter((r) =>
      ["admin", "super-admin"].includes(r)
    )
    if (roles?.length > 0) {
      return true
    } else {
      return false
    }
  }, [user.role])

  const handleChange = (value, name) => {
    setAgent((prev) => ({ ...prev, [name]: value }))
  }

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event
    setAgent((prev) => ({
      ...prev,
      role: typeof value === "string" ? value.split(",") : value,
    }))
  }

  useMemo(() => {
    setAgent((prev) => ({ ...prev, ...user }))
  }, [])

  const onSaveUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErr(null)
    try {
      await saveProfileImage(agent)
      setUser((prev) => ({
        ...prev,
        ...agent,
        fname: `${agent?.f_name} ${agent?.l_name}`,
      }))
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  const disableEnter = (e) => {
    var key = e.charCode || e.keyCode || 0
    if (key == 13) {
      e.preventDefault()
    }
  }

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <Stack px={5} py={2}>
        <Typography variant="h6">Edit Profile</Typography>
        {err && <Alert severity="error">{err}</Alert>}
        <Box
          component="form"
          sx={{
            "& > :not(style)": { mb: 1, width: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
          noValidate
          autoComplete="off"
          onSubmit={onSaveUser}
          onKeyPress={disableEnter}
        >
          <Grid container sx={{ py: 2 }} spacing={2} columns={2}>
            <Grid item sm={1}>
              <TextField
                size="small"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                fullWidth
                value={agent?.f_name}
                onChange={(e) => handleChange(e.target.value, "f_name")}
              />
            </Grid>
            <Grid item sm={1}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={agent?.l_name}
                onChange={(e) => handleChange(e.target.value, "l_name")}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                value={agent?.email}
                onChange={(e) => handleChange(e.target.value, "email")}
              />
            </Grid>
            <Grid item sm={1}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Slack Id"
                variant="outlined"
                fullWidth
                value={agent?.slackId}
                onChange={(e) => handleChange(e.target.value, "slackId")}
              />
            </Grid>
            {isAllowed && (
              <Grid item sm={1}>
                <FormControl size="small" sx={{ minWidth: 250, width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Roles
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={agent?.role}
                    onChange={handleSelectChange}
                    input={<OutlinedInput label="Roles" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {["user", "agent", "manager", "admin", "super-admin"].map(
                      (name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={agent?.role?.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Stack direction={"row"} justifyContent="space-between">
            <ProfilePicture agent={agent} setAgent={setAgent} />
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              sx={{ width: "max-content", height: "max-content" }}
              color="success"
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  )
}
