import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"

const DataContext = createContext()

export const DataContextProvider = ({ children }) => {
  const [markets, setMarkets] = useState([])
  const [dealerships, setDealerships] = useState([])
  const [orgs, setOrgs] = useState([])
  const [agents, setAgents] = useState([])
  const [makes, setMakes] = useState([])
  const [usTzs, setUsTzs] = useState([])
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)

  const getCurrentUser = async () => {
    try {
      const [d, a, m] = await Promise.all([
        axios.get(`${URL}/dealerships/get`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/agents`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/constants/makes`, {
          withCredentials: true,
        }),
      ])
      setDealerships(d.data.dealerships)
      setOrgs(d.data.organisations)
      setAgents(a.data)
      setMakes(m.data)
    } catch (error) {}
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  const memoedValue = useMemo(
    () => ({
      markets,
      setMarkets,
      dealerships,
      setDealerships,
      orgs,
      setOrgs,
      agents,
      setAgents,
      usTzs,
      setUsTzs,
      loading,
      setLoading,
      show,
      setShow,
      err,
      setErr,
      makes,
    }),
    [markets, dealerships, orgs, agents, usTzs, loading, show, err, makes]
  )

  return (
    <DataContext.Provider value={memoedValue}>
      <>
        {children}
        <FeedBackBox show={show} loading={loading} err={err} />
      </>
    </DataContext.Provider>
  )
}

export default function useData() {
  return useContext(DataContext)
}
