import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Grid } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

const INITIAL = { email: "", phone: "", f_name: "", l_name: "", isOwner: false }

export default function PopUpDialogCreateUser({
  open,
  setOpen,
  setDealership,
  loading,
  setLoading,
  setErr,
  setShow,
  id,
}) {
  const [dealer, setDealer] = React.useState(INITIAL)

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChange = (value, name) => {
    setDealer((prev) => ({ ...prev, [name]: value }))
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    if (!dealer?.f_name || !dealer?.l_name) {
      setErr("Name is required")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!dealer?.email) {
      setErr("Please Add email")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!dealer?.phone) {
      setErr("Please add phone number")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }

    const data = {
      ...dealer,
      dealership: id,
    }

    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.post(`${URL}/users`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setDealership((prev) => ({
          ...prev,
          users: [...prev?.users, res.data?.user],
          admins: res.data?.user?.isOwner
            ? [...prev?.admins, res.data?.user?._id]
            : [...prev?.admins],
        }))
      }
      setShow(true)
      setLoading(false)
      setDealer(INITIAL)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle>Create New User</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container sx={{ py: 2 }} spacing={2} columns={2}>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              value={dealer?.f_name}
              onChange={(e) => handleChange(e.target.value, "f_name")}
            />
          </Grid>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={dealer?.l_name}
              onChange={(e) => handleChange(e.target.value, "l_name")}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              value={dealer?.email}
              onChange={(e) => handleChange(e.target.value, "email")}
            />
          </Grid>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              fullWidth
              value={dealer?.phone}
              onChange={(e) => handleChange(e.target.value, "phone")}
            />
          </Grid>
          <Grid item sm={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={dealer?.isOwner}
                    onChange={(e) => handleChange(e.target.checked, "isOwner")}
                  />
                }
                label="Owner?"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={submitDeal}>
          {loading ? "Creating..." : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
