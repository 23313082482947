import momentTz from "moment-timezone"
import moment from "moment"

export const selectTime = (tz, date, time) => {
  momentTz.tz.setDefault(tz)
  const d1 = moment(
    new Date().toLocaleString("en-US", {
      timeZone: tz,
    })
  ).format()
  const tx = momentTz.tz(d1, tz).format("Z")
  const dx = moment(new Date(`${date} ${time} ${tx}`)).format()
  const t = momentTz.tz(dx, tz).toISOString(true)
  return t
}

export const FormatDateZone = (date, tz) => {
  const t = momentTz.tz(date, tz).format("MM/DD/YY @ h A")
  return t
}
