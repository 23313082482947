import { Divider, Stack, Typography } from "@mui/material"
import axios from "axios"
import momentTz from "moment-timezone"
import React from "react"
import { URL } from "../../config/apis"
import { formatPrice } from "../../helppers/formatters"
import { DashboardContext } from "../../pages/Dashboard"
import AppointmentsBox from "./AppointmentsBox"
import NoteView from "./NoteView"

export default function SnapShotView() {
  const { selected, setSelected } = React.useContext(DashboardContext)

  const [data, setData] = React.useState(null)
  const [err, setErr] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const getData = async () => {
    setErr(null)
    setLoading(true)
    try {
      const res = await axios.get(`${URL}/leads/view/${selected}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setData(res.data)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    getData()
  }, [selected])

  if (loading) {
    return <>loading</>
  }

  if (err) {
    return <>{err}</>
  }

  return (
    <AppointmentsBox label={data?.title} movePge={setSelected} isClose={true}>
      <Stack
        sx={{ px: 3, py: 1, maxHeight: 381, overflow: "auto" }}
        spacing={2}
      >
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight={600}>
            {data?.title}
          </Typography>
          <Stack
            direction={"row"}
            columnGap={2}
            rowGap={1}
            flexWrap="wrap"
            alignItems={"center"}
          >
            <Typography variant="body2"> {data?.vin}</Typography>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Typography variant="body2">
              {" "}
              {data?.mileage?.toLocaleString()} miles
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            columnGap={2}
            rowGap={1}
            flexWrap="wrap"
            alignItems={"center"}
          >
            <Typography variant="body2">
              {" "}
              {data?.city} / {data?.state}
            </Typography>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Typography variant="body2">Market: {data?.market?.name}</Typography>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Typography variant="body2">Name: {data?.sellerName}</Typography>
            <Divider orientation="vertical" variant="fullWidth" flexItem />
            <Typography variant="body2">Phone: {data?.sellerPhone}</Typography>
          </Stack>
        </Stack>
        <Divider variant="fullWidth" />
        <Stack>Agreed price: {formatPrice(data?.agreedPrice)}</Stack>
        <Divider variant="fullWidth" />
        <Stack
          direction={"row"}
          columnGap={2}
          rowGap={1}
          flexWrap="wrap"
          alignItems={"center"}
        >
          <Typography>
            Date:{" "}
            {momentTz
              .tz(data?.appointment?.start, data?.appointment?.tz)
              .format("dddd MMM DD YYYY")}
          </Typography>
          <Divider orientation="vertical" variant="fullWidth" flexItem />
          <Typography>
            Time:{" "}
            {momentTz
              .tz(data?.appointment?.start, data?.appointment?.tz)
              .format("h:mm A")}
          </Typography>
          <Divider orientation="vertical" variant="fullWidth" flexItem />
          <Typography>Status: {data?.appointment?.status}</Typography>
          {/* <Typography>Address: 1030 W Manchester Blvd</Typography> */}
        </Stack>
        <Divider variant="fullWidth" />
        <NoteView notes={data?.notes} />
      </Stack>
    </AppointmentsBox>
  )
}
