import React, { useMemo, useState } from "react"
import moment from "moment"
import { ListItem, Stack, Typography } from "@mui/material"

export default function IncomeMessage({ message, part }) {
  const [state, setState] = useState({
    hasMedia: message?.type === "media",
    mediafailed: false,
    mediaUrl: null,
  })

  useMemo(() => {
    if (state.hasMedia) {
      message?.media
        .getContentTemporaryUrl()
        .then((url) => setState({ ...state, mediaUrl: url }))
        .catch((e) => setState({ ...state, mediafailed: true }))
    }
  }, [message])

  return (
    <>
      <ListItem sx={{ justifyContent: "start" }}>
        <Stack
          bgcolor={part?.type === "sms" ? "#ffc107" : "#e6e6ed"}
          sx={{
            color: "black",
            maxWidth: "80%",
            py: 1,
            px: 2,
            borderRadius: "0px 10px 10px 10px",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
          >
            <Typography
              color={part?.type === "sms" ? "#d4293d" : "inherit"}
              variant="subtitle2"
            >
              {" "}
              {part?.attributes?.name ||
                part?.attributes?.fname ||
                message?.author}
            </Typography>
            <Typography variant="caption">
              {moment(message?.dateCreated).format("MM/DD/YY h:mm A")}
            </Typography>
          </Stack>
          <div>
            <Typography variant="body2">
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: message?.body }}
              />
            </Typography>
            {state.hasMedia && state.mediaUrl && !state.mediafailed ? (
              <span
                //onClick={() => openImage(state.mediaUrl)}
                className="font-medium cursor-pointer text-gray-600"
              >
                <img width={100} src={state.mediaUrl} />
              </span>
            ) : (
              <>
                {state.hasMedia && state.mediafailed && (
                  <Typography variant="subtitle2">
                    Failed to load media
                  </Typography>
                )}
              </>
            )}
          </div>
        </Stack>
      </ListItem>
    </>
  )
}
