import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import Slide from "@mui/material/Slide"
import { Box, Container, Grid, LinearProgress, Stack } from "@mui/material"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import {
  uploadImage,
  uploadimages,
  UploadUrlString,
  UploadUrlStringimgs,
} from "../../helppers/images/uploadImages"
import {
  deleteFromFB,
  deleteOneFromFB,
  deleteOneFromFBCreate,
} from "../../helppers/images/deleteImages"
import {
  handleDrag,
  handleDragIn,
  handleDragOut,
} from "../../helppers/functions"

const _url =
  "https://images.unsplash.com/photo-1619658535018-5a55d32e4628?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=701&q=80"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PopDownUpDialog({ open, setOpen, setLead, lead }) {
  const imagesRef = React.useRef()
  const imageRef = React.useRef()
  const [isloading, setIsLoading] = React.useState(false)
  const [uploading, setUploading] = React.useState(false)
  const [drag, setDrag] = React.useState(false)
  const [isdrag, setIsDrag] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [dragCounter, setDragCounter] = React.useState(0)
  const [drag_Counter, setDrag_Counter] = React.useState(0)

  const handleClose = () => {
    setOpen(false)
  }

  const handlePastImage = (event, x, setUploading) => {
    const items = (event?.clipboardData || event?.originalEvent?.clipboardData)
      ?.items
    let blob = null
    for (let i = 0; i < items?.length; i++) {
      if (items[i]?.type?.indexOf("image") === 0) {
        blob = items[i]?.getAsFile()
      }
    }
    if (blob !== null) {
      setUploading(true)
      const reader = new FileReader()
      reader.onload = async (event) => {
        if (x === 1) {
          UploadUrlString(
            event.target.result,
            setUploading,
            "cars",
            lead,
            setLead
          )
        } else if (x === 2) {
          UploadUrlStringimgs(
            event.target.result,
            setUploading,
            "cars",
            setLead
          )
        }
      }
      reader.readAsDataURL(blob)
    }
  }

  const uploadFImage = async (file) => {
    uploadImage(file, setUploading, lead, setLead)
    if (imageRef.current != null) {
      imageRef.current.FileList = []
      imageRef.current.value = ""
    }
  }

  const deletefImage = async () => {
    if (lead?.f_image == null) return
    await deleteFromFB(lead?.f_image?.path)
    setLead((prev) => ({ ...prev, f_image: null }))
  }

  const DeleteImage = async (path, name) => {
    await deleteOneFromFBCreate(path)
    setLead((prev) => ({
      ...prev,
      images: [...prev?.images?.filter((img) => img?.name !== name)],
    }))
  }

  const handlUploadImages = async (files) => {
    await uploadimages(files, setIsLoading, "cars", setLead)
    if (imagesRef.current != null) {
      imagesRef.current.FileList = []
      imagesRef.current.value = ""
    }
  }

  const handleDrop = (e, setDragCounter, setDrag) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handlUploadImages(e.dataTransfer.files)
      setDragCounter(0)
    }
  }

  const handleDropImage = (e, setDragCounter, setDrag) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      uploadFImage(e.dataTransfer.files[0])
      setDragCounter(0)
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Upload Images
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Done
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <Grid container spacing={5} columns={3} width="100%">
          <Grid item sm={1}>
            <Stack spacing={2}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Upload Featured Image
              </Typography>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <div
                  onDragOver={handleDrag}
                  onDrop={(e) => handleDropImage(e, setDrag_Counter, setIsDrag)}
                  onDragEnter={(e) =>
                    handleDragIn(e, setDrag_Counter, setIsDrag)
                  }
                  onDragLeave={(e) =>
                    handleDragOut(e, setDrag_Counter, setIsDrag)
                  }
                  onPaste={(e) => handlePastImage(e, 1, setDrag_Counter)}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    height: "150px",
                    border: "4px dashed #ccc",
                    position: "relative",
                  }}
                >
                  <span>Past / Drag & Drop here or</span>{" "}
                  <label
                    htmlFor="f_Image"
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#1976d2",
                      marginLeft: 5,
                    }}
                  >
                    <input
                      hidden
                      ref={imageRef}
                      type="file"
                      accept="images/*"
                      id="f_Image"
                      onChange={(e) => uploadFImage(e.target.files[0])}
                    />
                    Select
                  </label>
                  {isdrag && (
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2,
                        backgroundColor: "#ffffffe0",
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Drop Here
                    </div>
                  )}
                </div>
                {uploading && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                {lead?.f_image && (
                  <div style={{ position: "relative", width: "100%" }}>
                    <img
                      src={lead?.f_image?.url}
                      alt=""
                      style={{ width: "100%", height: "auto" }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 2,
                        right: 2,
                        backgroundColor: "#0000009e",
                      }}
                      color="primary"
                      component="label"
                      onClick={() => deletefImage()}
                    >
                      <DeleteForeverIcon
                        fontSize="medium"
                        sx={{ color: "#fff" }}
                      />
                    </IconButton>
                  </div>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={2}>
            <Stack spacing={2}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Upload Images
              </Typography>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <div
                  onDragOver={handleDrag}
                  onDrop={(e) => handleDrop(e, setDragCounter, setDrag)}
                  onDragEnter={(e) => handleDragIn(e, setDragCounter, setDrag)}
                  onDragLeave={(e) => handleDragOut(e, setDragCounter, setDrag)}
                  onPaste={(e) => handlePastImage(e, 2, setIsLoading)}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    height: "150px",
                    border: "4px dashed #ccc",
                    position: "relative",
                  }}
                >
                  <span>Past / Drag & Drop here or</span>{" "}
                  <label
                    htmlFor="Images"
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#1976d2",
                      marginLeft: 5,
                    }}
                  >
                    <input
                      hidden
                      type="file"
                      accept="images/*"
                      id="Images"
                      multiple={true}
                      onChange={(e) => handlUploadImages(e.target.files)}
                    />
                    Select
                  </label>
                  {drag && (
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2,
                        backgroundColor: "#ffffffe0",
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Drop Here
                    </div>
                  )}
                </div>
                {isloading && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                <Stack
                  direction="row"
                  gap={2}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  {lead?.images &&
                    lead?.images?.map((image, i) => (
                      <div
                        key={image?.name + i}
                        style={{
                          position: "relative",
                          width: "150px",
                          height: "150px",
                        }}
                      >
                        <img
                          src={image?.url}
                          alt=""
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "#0000009e",
                          }}
                          color="primary"
                          component="label"
                          onClick={() => DeleteImage(image?.path, image?.name)}
                        >
                          <DeleteForeverIcon
                            fontSize="small"
                            sx={{ color: "#fff" }}
                          />
                        </IconButton>
                      </div>
                    ))}
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}
