import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Grid } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

const INITIAL = { name: "" }

export default function PopUpDialogCreateOrg({
  open,
  setOpen,
  setOrgs,
  loading,
  setLoading,
  setErr,
  setShow,
}) {
  const [org, setOrg] = React.useState(INITIAL)

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChange = (value, name) => {
    setOrg((prev) => ({ ...prev, [name]: value }))
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    if (!org?.name) {
      setErr("Please Add name")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }

    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.post(`${URL}/organisations`, org, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setOrgs((prev) => [...prev, res.data?.org])
      }
      setShow(true)
      setLoading(false)
      setOrg(INITIAL)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle>Create New Organisation</DialogTitle>

      <DialogContent sx={{ minWidth: 450 }}>
        <Grid container sx={{ py: 2 }} spacing={2} columns={1}>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              value={org?.name}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={submitDeal}>
          {loading ? "Creating..." : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
