import { Chip, Stack } from "@mui/material"
import React, { useMemo } from "react"
import useData from "../../context/Data"
import CancelIcon from "@mui/icons-material/Cancel"

const DealetChip = ({ did, unSendToDealers }) => {
  const { dealerships } = useData()
  const dealer = useMemo(() => {
    return dealerships?.find((d) => d?._id === did)
  }, [])
  return (
    <Chip
      size="small"
      color="primary"
      sx={{ p: 0.5 }}
      label={dealer?.dname}
      deleteIcon={<CancelIcon />}
      onDelete={() => unSendToDealers(did)}
    />
  )
}

export default function SentToBox({ list, unSendToDealers }) {
  return (
    <Stack
      direction={"row"}
      flexWrap="wrap"
      alignItems="center"
      rowGap={1}
      columnGap={1}
    >
      {list?.map((item) => (
        <DealetChip did={item} key={item} unSendToDealers={unSendToDealers} />
      ))}
    </Stack>
  )
}
