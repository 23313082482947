import Stack from "@mui/material/Stack"
import React from "react"
import MenuItem from "@mui/material/MenuItem"
import useData from "../../context/Data"
import SelectMenu from "../createPage/SelectMenu"
import { Typography } from "@mui/material"
import useAuth from "../../context/Auth"

export default function LeadsFilters({
  agent,
  setAgent,
  setPage,
  count,
  claimer,
  setClaimer,
  make,
  setMake,
}) {
  const { user } = useAuth()
  const { agents, makes } = useData()

  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      sx={{ mb: 2 }}
      spacing={2}
    >
      <Stack direction={"row"} spacing={2}>
        <SelectMenu
          handleChange={(v) => {
            setAgent(v)
            setPage(1)
          }}
          label={"Agents"}
          name={"agent"}
          value={agent}
          options={agents}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          {agents &&
            agents?.map((u, i) => (
              <MenuItem key={u?._id + i} value={u?._id}>
                {u?.fname}
              </MenuItem>
            ))}
        </SelectMenu>
        <SelectMenu
          handleChange={(v) => {
            setClaimer(v)
            setPage(1)
          }}
          label={"Claimer"}
          name={"claimer"}
          value={claimer}
          options={agents}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          <MenuItem value="Unclaimed">
            <em>Unclaimed</em>
          </MenuItem>
          <MenuItem
            style={{
              color: "#be0b30",
              fontWeight: 700,
            }}
            value={user?._id}
          >
            <em>Mine</em>
          </MenuItem>
          {agents &&
            agents
              ?.filter((a) => a?._id !== user?._id)
              ?.map((u, i) => (
                <MenuItem key={u?._id + i} value={u?._id}>
                  {u?.fname}
                </MenuItem>
              ))}
        </SelectMenu>
        <SelectMenu
          handleChange={(v) => {
            setMake(v)
            setPage(1)
          }}
          label={"Make"}
          name={"make"}
          value={make}
          options={makes}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          {makes &&
            makes?.map((m, i) => (
              <MenuItem
                sx={{ textTransform: "uppercase" }}
                key={m?._id + i}
                value={m?._id}
              >
                {m?._id?.toUpperCase()}
              </MenuItem>
            ))}
        </SelectMenu>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography variant="h6">{count}</Typography>
      </Stack>
    </Stack>
  )
}
