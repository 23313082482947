import { Stack, Typography } from "@mui/material"
import React from "react"
import FacebookIcon from "@mui/icons-material/Facebook"
import { formatPrice } from "../../helppers/formatters"
import IosShareIcon from "@mui/icons-material/IosShare"

export default function TopTileRightSide({ lead }) {
  return (
    <Stack
      direction="row"
      justifyContent={"end"}
      alignItems={"center"}
      spacing={5}
    >
      <a
        href={
          lead?.fbmpId
            ? `https://www.facebook.com/marketplace/item/${lead?.fbmpId}`
            : lead?.otherSource
            ? lead?.otherSource
            : "#"
        }
        target="_blank"
      >
        {lead?.fbmpId ? (
          <FacebookIcon
            style={{ color: "#116ecb" }}
            height={"3.5rem"}
            width={"3.5rem"}
            fontSize={"large"}
          />
        ) : lead?.otherSource?.includes("autotrader") ? (
          <img
            src="/aticon.png"
            height={35}
            width={35}
            alt="Autotrader UK"
          />
        ) : (
          <IosShareIcon height={"3.5rem"} width={"3.5rem"} fontSize={"large"} />
        )}
      </a>
      <Stack alignItems={"end"}>
        <Typography fontWeight={600} variant="h5">
          {formatPrice(lead?.listedPrice)}
        </Typography>
        <span>Listed Price</span>
      </Stack>
    </Stack>
  )
}
