import { Divider, Stack } from "@mui/material"
import React from "react"
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    width: "100%",
    cursor: "pointer",
  },
}

export default function TopTitleLeftSide({ lead, setIsOpen }) {
  return (
    <div style={styles.container}>
      <div style={styles.title} onClick={() => setIsOpen(true)}>
        <h3>{lead?.title}</h3>
        <PhotoLibraryIcon />
      </div>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <span>{lead?.vrm}</span>
        <span>{lead?.vin}</span>
        <span>{lead?.mileage?.toLocaleString()} miles</span>
        {lead?.fuel === "Electric" ? (
          <>
            <span>{lead?.battery_capacity}</span>
            <span>{lead?.battery_range}</span>
          </>
        ) : (
          <span>{lead?.engine}</span>
        )}
        <span>{lead?.transmission}</span>
      </Stack>
    </div>
  )
}
