import "./App.css"
import { Routes, Route, Navigate } from "react-router-dom"
import { CreateNewListing, DealershipsPage, Home } from "./pages"
import SingleLeadpage from "./pages/SingleLeadpage"
import useAuth from "./context/Auth"
import EditLeadPage from "./pages/EditLeadPage"
import CreateNewDealershipPage from "./pages/CreateNewDealershipPage"
import DealershipPage from "./pages/DealershipPage"
import AgentsPage from "./pages/AgentsPage"
import AgentPage from "./pages/AgentPage"
import AcceptedPage from "./pages/AcceptedPage"
import CountersPage from "./pages/CountersPage"
import { PrivateRoutes } from "./components/layouts/PrivateRoutes"
import LoginPage from "./pages/LoginPage"
import CalendarPage from "./pages/CalendarPage"
import DoneDealsPage from "./pages/DoneDealsPage"
import ProfilePage from "./pages/ProfilePage"
import Dashboard from "./pages/Dashboard"

function App() {
  const { user } = useAuth()

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route index element={<Dashboard />} />
        <Route path="/new" element={<CreateNewListing />} />

        <Route path="/leads" element={<Home />} />
        <Route path="/accepted" element={<AcceptedPage />} />
        <Route path="/counters" element={<CountersPage />} />
        <Route path="/deals" element={<DoneDealsPage />} />
        <Route path="/lost" element={<>lost</>} />
        <Route path="/trash" element={<>trash</>} />

        <Route path="/leads/item/:id" element={<SingleLeadpage />} />
        <Route path="/leads/edit/:id" element={<EditLeadPage />} />

        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/users" element={<AgentsPage />} />
        <Route path="/users/profile/:id" element={<AgentPage />} />

        <Route path="/appointments" element={<CalendarPage />} />

        <Route path="/dealerships" element={<DealershipsPage />} />
        <Route path="/dealerships/profile/:id" element={<DealershipPage />} />
        <Route path="/dealerships/new" element={<CreateNewDealershipPage />} />
        <Route path="*" element={<>Not found</>} />
      </Route>

      <Route
        path="/login"
        element={!user ? <LoginPage /> : <Navigate to={"/"} />}
      />
      {/* <Route path="*" element={<>Not found</>} /> */}
    </Routes>
  )
}

export default App
