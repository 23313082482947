import { Chip, Stack } from "@mui/material"
import React, { useMemo } from "react"
import { formatPrice } from "../../../helppers/formatters"
import { MdOutlineAccessTime } from "react-icons/md"

export const SentToDealers = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} flexWrap="wrap">
      Sent To :{" "}
      {parsed?.dealers?.map((d, i) => (
        <Chip
          label={d?.dname}
          key={d?._id - i}
          sx={{
            fontSize: 12,
            px: 0.2,
            py: 0.5,
            height: "max-content",
            fontWeight: 500,
            ml: 1,
          }}
        />
      ))}
    </Stack>
  )
}

export const UnSentToDealers = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} flexWrap="wrap">
      {parsed?.txt} :{" "}
      <Chip
        label={parsed?.dealer}
        color="warning"
        sx={{
          fontSize: 12,
          px: 0.2,
          py: 0.5,
          height: "max-content",
          fontWeight: 500,
          ml: 1,
        }}
      />
    </Stack>
  )
}

export const CounterSent = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={2} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const AcceptCounter = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)} from:</b>
      <b style={{ color: "green" }}>{parsed?.dealer}</b>
    </Stack>
  )
}

export const AcceptOnBehalf = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>{parsed?.txt}</span>
      <b style={{ color: "green" }}>{parsed?.dealer}</b>
      <b> - Amount: {formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const DealLine = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <b>{parsed?.dealer}</b>
      <span>Purchased this Vehicle for</span>
      <b style={{ color: "green" }}>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const PreNegSent = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={2} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const AppointmentCreated = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>{parsed?.txt} for:</span>
      <b>{parsed?.dealer}</b>

      <span style={{ display: "flex", alignItems: "center", color: "#444ce7" }}>
        <MdOutlineAccessTime fontSize={20} />
        <b style={{ marginLeft: 2 }}> {parsed?.start || parsed?.date}</b>
      </span>
    </Stack>
  )
}
