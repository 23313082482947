import * as React from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import Avatar from "@mui/material/Avatar"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import PersonIcon from "@mui/icons-material/Person"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import { blue } from "@mui/material/colors"
import { URL } from "../../config/apis"
import axios from "axios"

function SimpleDialog({ setOpen, setDealership, open, dealership }) {
  const [users, setUsers] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [isloading, setIsLoading] = React.useState(false)
  const [success, setSucess] = React.useState(null)
  const [err, setErr] = React.useState(null)

  const handleClose = () => {
    setOpen(false)
  }

  const handleListItemClick = async (value) => {
    setIsLoading(true)
    const data = {
      dealership: dealership?._id,
      userId: value?._id,
      organisation: dealership?.organisation,
    }
    try {
      const res = await axios.put(`${URL}/users/add-to`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })

      if (res.data.ok) {
        setDealership((prev) => ({ ...prev, users: [...prev?.users, value] }))
        setIsLoading(false)
        setSucess(true)
        setTimeout(() => {
          setOpen(false)
          setSucess(null)
        }, 1000)
      }
    } catch (error) {
      setIsLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 2000)
    }
  }

  const getUsers = async () => {
    setLoading(true)

    try {
      const res = await axios.get(
        `${URL}/users/dealership/${dealership?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setUsers(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
    }
  }

  React.useEffect(() => {
    getUsers()
  }, [])

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select Existing user</DialogTitle>
      {loading ? (
        <>Loading users ...</>
      ) : (
        <List sx={{ pt: 0 }}>
          {users?.map((user) => (
            <ListItem
              button
              onClick={() => handleListItemClick(user)}
              key={user?._id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user?.fname} />
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  )
}

export default SimpleDialog
