import { Grid, Stack } from "@mui/material"
import React from "react"
import CheckMarkSelect from "./CheckMarkSelect"
import NumberRange from "./NumberRange"
import YearRange from "./YearRange"

export default function DealershipCriteria({ criteria, setCreiteria }) {
  return (
    <Stack sx={{ px: 2, py: 2, width: "100% !important" }} spacing={2}>
      <div>Buying Criteria</div>
      <Grid sx={{ width: "100% !important" }} container columns={3} spacing={2}>
        <Grid item sm={3} md={1}>
          <YearRange selected={criteria?.year} setCreiteria={setCreiteria} />
        </Grid>
        <Grid item sm={3} md={1}>
          <NumberRange
            xkey={"price"}
            sign={true}
            title="Price"
            selected={criteria?.price}
            setCreiteria={setCreiteria}
          />
        </Grid>
        <Grid item sm={3} md={1}>
          <NumberRange
            xkey={"mileage"}
            title="Mileage"
            selected={criteria?.mileage}
            setCreiteria={setCreiteria}
          />
        </Grid>
      </Grid>

      <CheckMarkSelect
        data={[
          { name: "Title In Hand" },
          { name: "Loan on Vehicle" },
          { name: "Lease on Vehicle" },
        ]}
        selected={criteria?.titleStatus}
        setCreiteria={setCreiteria}
        title="Select Title Status"
        xkey="titleStatus"
      />
    </Stack>
  )
}
