import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { CardMedia, Chip, Stack } from "@mui/material"

const ProfileBox = ({ user }) => {
  return (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" fontWeight={600} component="div">
          {user?.fname}
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{ my: 1.5 }}
          color="text.secondary"
        >
          {user?.role?.map((r, i) => (
            <Chip
              size="small"
              sx={{ textTransform: "capitalize" }}
              label={r}
              key={r + i}
            />
          ))}
        </Stack>
        <Typography variant="body2"> {user?.email}</Typography>
        <Typography variant="body2"> {user?.phone}</Typography>
      </CardContent>
    </React.Fragment>
  )
}

export default function ProfileCard({ user }) {
  return (
    <Box sx={{ width: "max-content", minWidth: "550px" }}>
      <Card variant="outlined" sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={user?.avatar?.url || "/no-image.png"}
          alt="Live from space album cover"
        />
        <ProfileBox user={user} />
      </Card>
    </Box>
  )
}
