import * as React from "react"
import { styled, useTheme } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import { Link, NavLink } from "react-router-dom"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import InboxIcon from "@mui/icons-material/MoveToInbox"
import MailIcon from "@mui/icons-material/Mail"
import { botMenu, navMenu } from "../../helppers/constants"
import { appName } from "../../constants/constants"
import { Stack } from "@mui/material"

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

function SideBar({ drawerWidth, open, handleDrawerClose }) {
  const theme = useTheme()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Stack  direction={"row"} pr={1} alignItems="center">
          <img src="/theautofinder-main.svg" style={{ width: 170 }} alt='The Auto Finder'  />
          {/* <Typography
            variant="h5"
            sx={{ color: "#0059ff" }}
            fontWeight={700}
            noWrap
            component="div"
          >
            The Auto Finder
          </Typography> */}
        </Stack>
        {/* <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            fontWeight: 700,
            color: "inherit",
            width: "100%",
          }}
          paddingX={2}
        >
          {appName}
        </Typography> */}
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navMenu.map((item, index) => (
          <ListItem key={item.href + index} disablePadding>
            <NavLink
              to={item.href}
              style={({ isActive }) => ({
                color: isActive ? "black" : "inherit",
                textDecoration: "none",
                width: "100%",
              })}
            >
              {({ isActive }) => (
                <ListItemButton
                  sx={{ backgroundColor: isActive ? "#00000026" : "inherit" }}
                >
                  <ListItemIcon sx={{ color: isActive ? "black" : "inherit" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.lable} />
                </ListItemButton>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {botMenu.map((item, index) => (
          <ListItem key={item.href + index} disablePadding>
            <NavLink
              to={item.href}
              style={({ isActive }) => ({
                color: isActive ? "black" : "inherit",
                textDecoration: "none",
                width: "100%",
              })}
            >
              {({ isActive }) => (
                <ListItemButton
                  sx={{ backgroundColor: isActive ? "#00000026" : "inherit" }}
                >
                  <ListItemIcon sx={{ color: isActive ? "black" : "inherit" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.lable} />
                </ListItemButton>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default SideBar
