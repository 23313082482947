import { IconButton, LinearProgress, Stack } from "@mui/material"
import { Box } from "@mui/material"
import React from "react"
import {
  handleDrag,
  handleDragIn,
  handleDragOut,
} from "../../helppers/functions"
import { deleteFromFB } from "../../helppers/images/deleteImages"
import { uploadProfileImage } from "../../helppers/images/uploadImages"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { saveProfileImage } from "../../helppers/saveProfile"
import useAuth from "../../context/Auth"

export default function ProfilePicture({ agent, setAgent }) {
  const { setUser } = useAuth()

  const imageRef = React.useRef()
  const [uploading, setUploading] = React.useState(false)
  const [isdrag, setIsDrag] = React.useState(false)
  const [drag_Counter, setDrag_Counter] = React.useState(0)

  const uploadPImage = async (file) => {
    uploadProfileImage(file, setUploading, agent, setAgent, setUser)
    if (imageRef.current != null) {
      imageRef.current.FileList = []
      imageRef.current.value = ""
    }
  }

  const deletefImage = async () => {
    if (agent?.avatar == null) return
    setUploading(true)
    await deleteFromFB(agent?.avatar?.path)
    await saveProfileImage({
      avatar: null,
    })
    setAgent((prev) => ({ ...prev, avatar: null }))
    setUser((prev) => ({ ...prev, avatar: null }))
    setUploading(false)
  }

  const handleDropImage = (e, setDragCounter, setDrag) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      uploadPImage(e.dataTransfer.files[0])
      setDragCounter(0)
    }
  }
  return (
    <Stack spacing={2} sx={{ width: "200px", height: "200px" }}>
      <div
        onDragOver={handleDrag}
        onDrop={(e) => handleDropImage(e, setDrag_Counter, setIsDrag)}
        onDragEnter={(e) => handleDragIn(e, setDrag_Counter, setIsDrag)}
        onDragLeave={(e) => handleDragOut(e, setDrag_Counter, setIsDrag)}
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 1,
          height: "100%",
          border: isdrag ? "4px dashed #ccc" : "none",
          position: "relative",
          flexDirection: "column",
          background: `no-repeat center cover`,
          backgroundSize: "cover",
          backgroundImage: `url(${agent?.avatar?.url || "/no-image.png"}) `,
        }}
      >
        {!isdrag && (
          <Stack
            sx={{ bgcolor: "#fff", width: "100%", fontSize: 12 }}
            alignItems="center"
          >
            <span>Drag & Drop here or</span>{" "}
            <label
              htmlFor="f_Image"
              style={{
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer",
                color: "#1976d2",
                marginLeft: 5,
              }}
            >
              <input
                hidden
                ref={imageRef}
                type="file"
                accept="images/*"
                id="f_Image"
                onChange={(e) => uploadPImage(e.target.files[0])}
              />
              Select
            </label>
          </Stack>
        )}
        {isdrag && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              backgroundColor: "#ffffffe0",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            Drop Here
          </div>
        )}
        {agent?.avatar?.url && !isdrag && (
          <IconButton
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
              backgroundColor: "#0000009e",
            }}
            color="primary"
            component="label"
            onClick={() => deletefImage()}
          >
            <DeleteForeverIcon fontSize="medium" sx={{ color: "#fff" }} />
          </IconButton>
        )}
      </div>
      {uploading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Stack>
  )
}
