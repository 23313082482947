import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import {
  Avatar,
  Badge,
  Chip,
  Divider,
  ListItemAvatar,
  Stack,
  Typography,
} from "@mui/material"
import useTwilioChat from "../../context/TwilioChat"
import { useMemo } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { doHtmlDisplay } from "../../helppers/formatters"
import useData from "../../context/Data"

const CustomList = ({ conv, setOpen }) => {
  const navigate = useNavigate()
  const { unreadCount, setunreadCount } = useTwilioChat()
  const { agents } = useData()
  const [lastMessage, setlastMessage] = useState(null)
  const count = useMemo(() => {
    const c = unreadCount?.find((x) => x?.id === conv?.sid)
    return c?.count || 0
  }, [conv, unreadCount])

  useMemo(() => {
    return conv.getMessages(1).then((m) => {
      setlastMessage(m.items[0])
    })
  }, [conv])

  const name = useMemo(() => {
    if (!lastMessage?.author?.includes("@")) {
      return lastMessage?.author
    }
    const u = agents?.find((a) => a?.email === lastMessage?.author)
    return u?.fname || lastMessage?.author
  }, [lastMessage, agents])

  const handleClick = async () => {
    await conv?.setAllMessagesRead()
    setunreadCount((prev) => [...prev?.filter((c) => c?.id !== conv?.sid)])
    navigate(`/leads/item/${conv?.uniqueName}`)
    setOpen(false)
  }

  return (
    <ListItem
      onClick={handleClick}
      alignItems="flex-start"
      sx={{ pr: 4, cursor: "pointer", "&:hover": { bgcolor: "#444ce73d" } }}
    >
      <ListItemAvatar>
        <Avatar
          alt={conv?.friendlyName}
          src={conv?.attributes?.image || "/placeholder.png"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={conv?.friendlyName}
        secondary={
          lastMessage ? (
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {name || "lastMessage?.author"}
              </Typography>
              {` — ${doHtmlDisplay(lastMessage?.body)}`}
            </React.Fragment>
          ) : (
            <React.Fragment>Send the first SMS</React.Fragment>
          )
        }
      />
      <Badge
        badgeContent={count}
        color="error"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <span style={{ height: 25, width: 2 }} />
      </Badge>
    </ListItem>
  )
}

export default function ConversationsDrawer({ open, setOpen }) {
  const { unreadCount, conversations } = useTwilioChat()

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Drawer anchor={"right"} open={open} onClose={toggleDrawer}>
      <Box sx={{ width: 400 }} role="presentation">
        <Stack px={2} pt={2} pb={1}>
          <Typography variant="h5">All Conversations</Typography>
        </Stack>
        <Divider />
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {conversations &&
            conversations?.map((c, i) => (
              <>
                <CustomList key={c?.sid + i} conv={c} setOpen={setOpen} />
                {conversations?.length - 1 !== i && (
                  <Divider key={c?.sid + i} variant="inset" component="li" />
                )}
              </>
            ))}
        </List>
      </Box>
    </Drawer>
  )
}
