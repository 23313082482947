import axios from "axios"
import { URL } from "../../config/apis"

export const updateLead = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/leads/item/${id}/chat`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    return res.data
  } catch (error) {
    return error
  }
}
