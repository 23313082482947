import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import GppBadIcon from "@mui/icons-material/GppBad"
import { ButtonGroup, IconButton } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import moment from "moment"

const UserRow = ({ user, isAdmin }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {user?.fname}
      </TableCell>
      <TableCell align="left">{user?.email}</TableCell>
      <TableCell align="left">{user?.phone}</TableCell>
      <TableCell align="center">
        {user?.isOwner ? (
          <VerifiedUserIcon color="success" />
        ) : (
          <GppBadIcon color="error" />
        )}
      </TableCell>
      <TableCell align="center">
        {isAdmin ? (
          <VerifiedUserIcon color="success" />
        ) : (
          <GppBadIcon color="error" />
        )}
      </TableCell>
      <TableCell sx={{ fontSize: 12, fontWeight: "500" }} align="left">
        {user?.lastLogin
          ? moment(user?.lastLogin).format("DD/MM/YY : h:mm A")
          : "N/A"}
      </TableCell>
      <TableCell sx={{ fontSize: 12, fontWeight: "500" }} align="left">
        {user?.lastActive
          ? moment(user?.lastActive).format("DD/MM/YY : h:mm A")
          : "N/A"}
      </TableCell>
      {/* <TableCell align="center">
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
        >
          <IconButton color="primary" component="button">
            <EditIcon />
          </IconButton>
          <IconButton color="error" component="button">
            <DeleteForeverIcon />
          </IconButton>
        </ButtonGroup>
      </TableCell> */}
    </TableRow>
  )
}

export default function UsersTable({ users, setDealership, admins }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="center">Is Owner</TableCell>
            <TableCell align="center">Is Admin</TableCell>
            <TableCell align="center">Last Login</TableCell>
            <TableCell align="center">Last Action</TableCell>
            {/* <TableCell align="center">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users?.map((user) => (
              <UserRow
                user={user}
                key={user?._id}
                isAdmin={admins?.includes(user?._id)}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
