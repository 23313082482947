import axios from "axios"
import { URL } from "../config/apis"

export const saveProfileImage = async (data) => {
  try {
    await axios.put(`${URL}/agents/profile`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
  } catch (error) {
    console.log(error)
  }
}
