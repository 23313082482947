import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import useData from "../../../context/Data"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function SelectWithCheckMark({
  selected,
  setSelected,
  selectedDealers,
  setSelectedDealers,
  list,
}) {
  const { dealerships } = useData()
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedDealers(value)
    setSelected(value?.map((v) => v?._id))
  }

  const isSelected = (id) => {
    // const d = selectedDealers?.find((i) => i?._id === id)
    // if (!d) return false
    return selected?.includes(id)
  }

  return (
    <div style={{ width: "100%" }}>
      <FormControl size="small" sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Dealers</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedDealers}
          onChange={handleChange}
          input={<OutlinedInput sx={{ width: "100%" }} label="Dealers" />}
          renderValue={(selected) => selected?.map((i) => i?.dname)?.join(", ")}
          MenuProps={MenuProps}
        >
          {dealerships &&
            dealerships
              ?.filter((d) => !list?.includes(d?._id) && d?.isActive)
              ?.map((name) => (
                <MenuItem key={name?._id} value={name}>
                  <Checkbox checked={isSelected(name?._id)} />
                  <ListItemText primary={name?.dname} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  )
}
