export const Months = [
  { name: "January", code: "Jan" },
  { name: "February", code: "Feb" },
  { name: "March", code: "Mar" },
  { name: "April", code: "Apr" },
  { name: "May", code: "May" },
  { name: "June", code: "Jun" },
  { name: "July", code: "Jul" },
  { name: "August", code: "Aug" },
  { name: "September", code: "Sep" },
  { name: "October", code: "Oct" },
  { name: "November", code: "Nov" },
  { name: "December", code: "Dec" },
]
export const appName = "Control Panel"
export const imgUrl =
  "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e?w=500&h=400&fit=crop&auto=format"
export const sHistory = [
  "Full main dealer history",
  "Part main dealer history",
  "Full service history",
  "Part service history",
  "No service history",
]
