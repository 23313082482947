import { Box, Divider, List, ListItem, ListItemText, Stack } from "@mui/material"
import React, { useEffect } from "react"

export default function LatestUpdates() {
  // const [updates, setUpdates] = React.useState([])
  // return (
  //   <List
  //     sx={{
  //       width: "100%",
  //       maxWidth: "100%",
  //       bgcolor: "background.paper",
  //       overflow: "auto",
  //       height: 381,
  //     }}
  //   >
  //     {/* {updates.map((item, i) => (
  //       <>
  //         <ListItem>
  //           <ListItemText
  //             primary="New Counter"
  //             secondary={`From Dealer X : $${item.id?.toLocaleString()}`}
  //           />
  //         </ListItem>
  //         {i !== updates.length - 1 && (
  //           <Divider
  //             key={"divider" + i}
  //             sx={{ marginLeft: 0 }}
  //             variant="inset"
  //             component="li"
  //           />
  //         )}
  //       </>
  //     ))} */}
  //   </List>
  // )

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 381}} >Coming Soon</Box>
  )
}
