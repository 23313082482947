import React from "react"
import SearchIcon from "@mui/icons-material/Search"
import { alpha, styled } from "@mui/material/styles"
import { InputBase } from "@mui/material"
import PositionedPopper from "./SearchRes"
import axios from "axios"
import { URL } from "../../config/apis"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}))

export default function SearchBox() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [isDone, setIsDone] = React.useState(false)
  const [results, setResults] = React.useState([])

  const handleFocus = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = (event) => {
    setAnchorEl(null)
    setOpen(false)
  }

  const onValueCahnge = async (e) => {
    const { value } = e.target
    if (value?.trim() == null || value?.trim() == "") return
    if (value?.trim()?.length >= 4) {
      setErr(null)
      setLoading(true)
      setIsDone(true)
      try {
        const res = await axios.post(
          `${URL}/search`,
          { q: value },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        setResults(res.data)
      } catch (error) {
        setErr(error.response?.data?.message || error.message)
      }
      setLoading(false)
    }
  }

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onFocus={handleFocus}
        onChange={onValueCahnge}
      />
      <PositionedPopper
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        err={err}
        isDone={isDone}
        loading={loading}
        results={results}
        setAnchorEl={setAnchorEl}
        setOpen={setOpen}
      />
    </Search>
  )
}
