import * as React from "react"
import Grid from "@mui/material/Grid"

export default function GirdBoxWrraper({ children }) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  )
}
