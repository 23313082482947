import React from "react"
import { useMemo } from "react"
import useData from "../../context/Data"

export default function AgnetBox({id}) {
  const { agents } = useData()
  const agent = useMemo(() => {
    return agents?.find((a) => a?._id === id)
  }, [id, agents])

  return <>{agent?.fname}</>
}
