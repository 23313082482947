import React from "react"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import { Avatar, Stack, Typography } from "@mui/material"

export default function ListSkeleton() {
  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center", px: 2, py:1, mb:0.6 }}>
      <Skeleton variant="circular" animation="wave" width={40} height={40} />
      <Stack sx={{ flex: 1, ml: 2 }}>
        <Typography variant="h6" maxWidth={"70%"}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography variant="body2">
          <Skeleton animation="wave" />
        </Typography>
      </Stack>
    </Box>
  )
}
