import Container from "@mui/material/Container"
import Alert from "@mui/material/Alert"
import React from "react"
import axios from "axios"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import useAuth from "../context/Auth"
import Grid from "@mui/material/Grid"
import PassedAppointments from "../components/dashboard/PassedAppointments"
import UpcomingAppointmnets from "../components/dashboard/UpcomingAppointmnets"
import RightSide from "../components/dashboard/RightSide"
import { URL } from "../config/apis"

export const DashboardContext = React.createContext()

export default function Dashboard() {
  const { user } = useAuth()
  const [selected, setSelected] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [passed, setPassed] = React.useState([])
  const [upcoming, setUpcoming] = React.useState([])

  const getData = async () => {
    try {
      const [p, u] = await Promise.all([
        axios.get(`${URL}/calendar/dashboard/passed`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }),
        axios.get(`${URL}/calendar/dashboard/upcoming`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }),
      ])
      setPassed(p.data)
      setUpcoming(u.data)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    getData()
  }, [])

  const values = React.useMemo(() => {
    return {
      selected,
      setSelected,
      passed,
      upcoming,
      loading,
    }
  }, [selected, passed, upcoming, loading])

  return (
    <DashboardContext.Provider value={values}>
      <Container maxWidth="100%">
        <Alert icon={false} severity="primary">
          <Stack>
            <Typography color={"#424242"} variant="h5" fontWeight={700}>
              Welcome {user?.fname}!
            </Typography>
            <Typography sx={{ color: "gray" }} variant="body2" fontWeight={400}>
              {new Date().toDateString()}
            </Typography>
          </Stack>
        </Alert>
        <br />
        <Stack>
          <Grid container columns={14} spacing={3}>
            <Grid item sm={8}>
              <Grid container columns={8} spacing={3}>
                <PassedAppointments />
                <UpcomingAppointmnets />
              </Grid>
            </Grid>
            <Grid item sm={6} >
              <RightSide />
            </Grid>
          </Grid>
          <Stack></Stack>
        </Stack>
      </Container>
    </DashboardContext.Provider>
  )
}
