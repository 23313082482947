import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { URL } from "../config/apis"
import useAuth from "./Auth"
import { Client } from "@twilio/conversations"

const TwilioChatContext = createContext()

export const TwilioChatContextProvider = ({ children }) => {
  const { user } = useAuth()

  const [connect, setConnect] = useState({
    statusString: null,
    conversationsReady: false,
  })
  const [client, setClient] = useState(null)
  const [messageNot, setMessageNot] = useState([])
  const [unreadCount, setunreadCount] = useState([])
  const [conversations, setConversations] = useState([])

  const getToken = async (id) => {
    try {
      const res = await axios.get(`${URL}/twilio/token/${id}`, {
        withCredentials: true,
      })
      return res.data.token
    } catch (error) {
      return null
    }
  }

  const getUnreadMessages = (c) => {
    return new Promise(async (resolve, reject) => {
      c.getUnreadMessagesCount()
        .then((count) => {
          resolve({
            id: c.sid,
            count: count,
          })
        })
        .catch((err) => reject(err))
    })
  }

  useEffect(() => {
    if (user) {
      let token
      const initializeClinet = async () => {
        try {
          token = await getToken(user?.email)

          if (token) {
            const client = new Client(token)
            setClient(client)

            client.on("connectionStateChanged", (state) => {
              if (state === "connecting")
                setConnect({
                  statusString: "Connecting to Twilio…",
                  status: "info",
                })
              if (state === "connected") {
                setConnect({
                  statusString: "Connected",
                  status: "success",
                })
              }
              if (state === "disconnecting")
                setConnect({
                  statusString: "Disconnecting from Twilio…",
                  conversationsReady: false,
                  status: "info",
                })
              if (state === "disconnected")
                setConnect({
                  statusString: "Disconnected.",
                  conversationsReady: false,
                  status: "warning",
                })
              if (state === "denied") {
                setConnect({
                  statusString: "Failed to connect.",
                  conversationsReady: false,
                  status: "error",
                })
              }

              console.log(state)
            })

            client.on("tokenAboutToExpire", async () => {
              token = await getToken(user?.email)
              client.updateToken(token)
            })

            // messsage added
            client.on("messageAdded", async (message) => {
              const convos = await client.getSubscribedConversations()
              const cnvs = convos.items.sort((a, b) => {
                const ad =
                  a.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                const bd =
                  b.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                return bd - ad
              })
              let promises = []
              setConversations(cnvs)
              cnvs.forEach((c) => promises.push(getUnreadMessages(c)))
              const numb = await Promise.all(promises)

              setunreadCount(numb)
            })

            //conversationUpdated
            client.on("conversationUpdated", async (conversation) => {
              const convos = await client.getSubscribedConversations()
              const cnvs = convos.items.sort((a, b) => {
                const ad =
                  a.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                const bd =
                  b.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                return bd - ad
              })
              let promises = []
              setConversations(cnvs)
              cnvs.forEach((c) => promises.push(getUnreadMessages(c)))
              const numb = await Promise.all(promises)

              setunreadCount(numb)
            })

            //conversationJoined
            client?.on("conversationJoined", async (conversation) => {
              const convos = await client.getSubscribedConversations()
              const cnvs = convos.items.sort((a, b) => {
                const ad =
                  a.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                const bd =
                  b.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                return bd - ad
              })

              let promises = []
              setConversations(cnvs)
              cnvs.forEach((c) => promises.push(getUnreadMessages(c)))
              const numb = await Promise.all(promises)

              setunreadCount(numb)
            })

            //conversationLeft
            client?.on("conversationLeft", async (conversation) => {
              const convos = await client.getSubscribedConversations()
              const cnvs = convos.items.sort((a, b) => {
                const ad =
                  a.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                const bd =
                  b.lastMessage?.dateCreated?.getTime() ||
                  new Date("01/01/2000").getTime() / 1000
                return bd - ad
              })

              let promises = []
              setConversations(cnvs)
              cnvs.forEach((c) => promises.push(getUnreadMessages(c)))
              const numb = await Promise.all(promises)

              setunreadCount(numb)
            })
            //  // pushNotification
            //   client.on("pushNotification", (event) => {
            //     console.log("Push notification");
            //     if (event.type != "twilio.conversations.new_message") {
            //       return;
            //     }

            //     if (Notification.permission === "granted") {
            //       showNotification(event);
            //     } else {
            //       console.log(
            //         "Push notification is skipped",
            //         Notification.permission
            //       );
            //     }
            //   });
          }
        } catch (error) {
          console.log(error)
        }
      }
      initializeClinet()
    }
  }, [user])

  const count = useMemo(() => {
    return unreadCount?.map((c) => c?.count || 0)?.reduce((a, b) => a + b, 0)
  }, [unreadCount])

  const memoedValue = useMemo(
    () => ({
      client,
      setClient,
      messageNot,
      setMessageNot,
      unreadCount,
      setunreadCount,
      conversations,
      setConversations,
      connect,
      count,
    }),
    [client, messageNot, unreadCount, conversations, connect, count]
  )

  return (
    <TwilioChatContext.Provider value={memoedValue}>
      {children}
    </TwilioChatContext.Provider>
  )
}

export default function useTwilioChat() {
  return useContext(TwilioChatContext)
}
