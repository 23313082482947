import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import React, { useEffect } from "react"
import PricesInput from "../components/createPage/PricesInput"
import SelectMenu from "../components/createPage/SelectMenu"
import { LEAD_INITIAL_STATE } from "../constants/initialStates"
import GirdBoxWrraper from "../components/createPage/GirdBoxWrraper"
import { Grid, Typography } from "@mui/material"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import Stack from "@mui/material/Stack"
import PopDownUpDialog from "../components/modals/PopDownUpDialog"
import { SimpleVrmDecoder } from "../helppers/apiCalls/decoders"
import LoadingBackdrop from "../components/global/LoadingBackdrop"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import axios from "axios"
import { URL } from "../config/apis"
import { useNavigate, useParams } from "react-router-dom"
import BasicVehicleCheck from "../components/global/BasicVehicleCheck"
import FeedBackBox from "../components/global/FeedBackBox"
import TextEditor from "../components/text-editor/TextEditor"
import AutoCheckBox from "../components/createPage/AutoCheckBox"
import { sHistory } from "../constants/constants"

export default function EditLeadPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [lead, setLead] = React.useState(LEAD_INITIAL_STATE)
  const [data, setData] = React.useState(null)
  const [Err, setErr] = React.useState(null)

  const handleChangeSelect = (e, name) => {
    setLead((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    setLead((prev) => ({ ...prev, [name]: e }))
  }

  const getLeadById = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${URL}/leads/item/${id}?mode=edit`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data) {
        setLead((prev) => ({ ...prev, ...res.data }))
        //  await SimpleVrmDecoder(res.data?.vrm, setData)
      } else {
        setErr("Lead not found")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeadById()
  }, [])

  const EditLead = async (e) => {
    e.preventDefault()
    setErr(null)
    setShow(false)
    setIsLoading(true)
    try {
      const res = await axios.put(`${URL}/leads/item/${id}`, lead, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setShow(true)
        setIsLoading(false)
        setTimeout(() => {
          setShow(false)
          navigate(`/leads/item/${id}`)
        }, 1000)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        setErr(error.response?.data?.message)
      } else {
        setErr(error.message)
      }
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  if (loading) {
    return <div>Loading ...</div>
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { my: 1, width: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
          noValidate
          autoComplete="off"
          onSubmit={EditLead}
        >
          <GirdBoxWrraper>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Registration"
                variant="outlined"
                fullWidth
                value={lead?.vrm}
                // inputProps={{ maxLength: 8 }}
                onChange={(e) => onValueChange(e.target.value, "vrm")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                id="outlined-basic"
                label="VIN"
                variant="outlined"
                fullWidth
                value={lead?.vin}
                onChange={(e) => onValueChange(e.target.value, "vin")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Mileage"
                variant="outlined"
                fullWidth
                value={lead?.mileage?.toLocaleString()}
                onChange={(e) =>
                  onValueChange(
                    parseFloat((e.target.value || "0").replace(/,/g, "")),
                    "mileage"
                  )
                }
              />
            </Grid>
            <Grid item xs={5}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                >
                  <Button disabled={isLoading} type="submit" color="success">
                    {isLoading ? "Saving..." : "Save"}
                  </Button>
                </ButtonGroup>
              </div>
            </Grid>
          </GirdBoxWrraper>
          <>
            <GirdBoxWrraper>
              <Grid item xs={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Year"
                  variant="outlined"
                  value={lead?.year}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "year")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Make"
                  variant="outlined"
                  value={lead?.make}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "make")}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Model"
                  variant="outlined"
                  value={lead?.model}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "model")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Trim"
                  variant="outlined"
                  value={lead?.trim}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "trim")}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Transmission"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.transmission}
                  onChange={(e) =>
                    onValueChange(e.target.value, "transmission")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                {lead?.fuel === "Electric" ? (
                  <Stack direction={"row"} spacing={2}>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Battery Capacity"
                      variant="outlined"
                      sx={{ flexGrow: 1 }}
                      value={lead?.battery_capacity}
                      fullWidth
                      onChange={(e) =>
                        onValueChange(e.target.value, "battery_capacity")
                      }
                    />
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Battery Range"
                      variant="outlined"
                      sx={{ flexGrow: 1 }}
                      value={lead?.battery_range}
                      fullWidth
                      onChange={(e) =>
                        onValueChange(e.target.value, "battery_range")
                      }
                    />
                  </Stack>
                ) : (
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Engine"
                    variant="outlined"
                    sx={{ flexGrow: 1 }}
                    value={lead?.engine}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "engine")}
                  />
                )}
              </Grid>
            </GirdBoxWrraper>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <TextField
                size="small"
                id="outlined-basic"
                label="Fuel Type"
                variant="outlined"
                value={lead?.fuel}
                onChange={(e) => onValueChange(e.target.value, "fuel")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Vehicle Type"
                variant="outlined"
                value={lead?.type}
                onChange={(e) => onValueChange(e.target.value, "type")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Color"
                variant="outlined"
                value={lead?.color}
                onChange={(e) => onValueChange(e.target.value, "color")}
              />

              {/* <SelectMenu
                sx={{ minWidth: 150 }}
                label="Title Status"
                options={[
                  "Title In Hand",
                  "Loan on Vehicle",
                  "Lease on Vehicle",
                ]}
                value={lead?.titleStatus}
                handleChange={handleChangeSelect}
                name={"titleStatus"}
              >
                {["Title In Hand", "Loan on Vehicle", "Lease on Vehicle"]?.map(
                  (o, i) => (
                    <MenuItem key={o + i} value={o}>
                      {o}
                    </MenuItem>
                  )
                )}
              </SelectMenu> */}

              <TextField
                size="small"
                id="outlined-basic"
                label="FBMP Id"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                value={lead?.fbmpId}
                onChange={(e) => onValueChange(e.target.value, "fbmpId")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Other Source"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                value={lead?.otherSource}
                onChange={(e) => onValueChange(e.target.value, "otherSource")}
              />
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <PricesInput
                lable="Listed Price"
                value={lead?.listedPrice}
                onValueChange={onValueChange}
                name="listedPrice"
              />
              <PricesInput
                lable="MAX offer"
                value={lead?.weBuyAnyCar}
                onValueChange={onValueChange}
                name="weBuyAnyCar"
              />
              <PricesInput
                lable="AutoTrader Recommended Retail"
                value={lead?.at_retail}
                disabled={false}
                onValueChange={onValueChange}
                name="at_retail"
              />

              {/* {lead?.at_private && (
                <PricesInput
                  lable="Auto Trader Private"
                  value={lead?.at_private}
                  disabled={true}
                  //onValueChange={onValueChange}
                  name="at_private"
                />
              )}
              {lead?.at_trade && (
                <PricesInput
                  lable="Auto Trader Trade"
                  value={lead?.at_trade}
                  disabled={true}
                  //onValueChange={onValueChange}
                  name="at_trade"
                />
              )}
              {lead?.at_part_exchange && (
                <PricesInput
                  lable="Auto Trader Part Exchange"
                  value={lead?.at_part_exchange}
                  disabled={true}
                  //onValueChange={onValueChange}
                  name="at_part_exchange"
                />
              )} */}
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <TextField
                size="small"
                id="outlined-basic"
                label="Seller Name"
                variant="outlined"
                value={lead?.sellerName}
                onChange={(e) => onValueChange(e.target.value, "sellerName")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Mobile"
                variant="outlined"
                value={lead?.sellerPhone}
                onChange={(e) => onValueChange(e.target.value, "sellerPhone")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="City"
                variant="outlined"
                value={lead?.city}
                onChange={(e) => onValueChange(e.target.value, "city")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Post Code"
                variant="outlined"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={lead?.postCode}
                onChange={(e) => onValueChange(e.target.value, "postCode")}
              />
              <TextField
                size="small"
                id="outlined-basic-postCode"
                label="Postal Town"
                variant="outlined"
                //  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={lead?.postalTown}
                onChange={(e) => onValueChange(e.target.value, "postalTown")}
              />
              <TextField
                size="small"
                id="outlined-basic-fbName"
                label="FaceBook Name"
                variant="outlined"
                value={lead?.fbName}
                onChange={(e) => onValueChange(e.target.value, "fbName")}
              />
            </Box>
            <Divider />
            {/* start images */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                onClick={() => setIsOpen(true)}
                variant="contained"
                startIcon={<PhotoCamera />}
              >
                Manage Images
              </Button>
              <Divider orientation="vertical" flexItem />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Featured Image:</Typography>
                {lead?.f_image ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Images:</Typography>
                <Typography>
                  {lead?.images?.length > 0
                    ? lead?.images?.length
                    : "No Images"}{" "}
                  Uploaded
                </Typography>
              </Stack>
            </Stack>
            {/* end images */}
            <Divider />
            {/* text Editor */}
            <TextEditor value={lead?.condition} setLead={setLead} />
            <Divider />
            <Stack direction={"row"} alignItems="center" spacing={3}>
              <TextField
                size="small"
                id="outlined-basic-owners"
                label="Previous owners"
                variant="outlined"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={lead?.prevOwners}
                onChange={(e) => onValueChange(e.target.value, "prevOwners")}
              />
              <SelectMenu
                sx={{ minWidth: 250 }}
                label="Service History"
                options={sHistory}
                value={lead?.sHistory}
                handleChange={handleChangeSelect}
                name={"sHistory"}
              >
                {sHistory.map((o, i) => (
                  <MenuItem key={o + i} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </SelectMenu>
            </Stack>
            <Divider />
            <Box
              component="div"
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6">AutoCheck</Typography>
              <AutoCheckBox autoCheck={lead?.autoCheck} setLead={setLead} />
            </Box>
          </>
        </Box>
      </Container>

      <PopDownUpDialog
        open={isOpen}
        setOpen={setIsOpen}
        setLead={setLead}
        lead={lead}
      />
      <LoadingBackdrop open={loading || show} />
      <FeedBackBox loading={isLoading} show={show} err={Err} />
    </>
  )
}
