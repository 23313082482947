import { Box, Divider } from "@mui/material"
import PaperContainer from "../global/PaperContainer"
import ExtraData from "./ExtraData"
import LeadActivity from "./leadActivity"
import LeadInfo from "./LeadInfo"
import TopTitleSide from "./TopTitleSide"

export default function LeftSide({
  lead,
  activity,
  setActivity,
  data,
  setIsOpen,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <PaperContainer minHeight={"100px"}>
        <TopTitleSide lead={lead} setIsOpen={setIsOpen} />
        <Divider />
        <LeadInfo lead={lead} />
        <Divider />
        <ExtraData data={data} lead={lead} />
      </PaperContainer>
      <PaperContainer minHeight={"300px"}>
        <LeadActivity activity={activity} setActivity={setActivity} />
      </PaperContainer>
    </Box>
  )
}
