import * as React from "react"
import Paper from "@mui/material/Paper"
import {
  ViewState,
  IntegratedEditing,
  EditingState,
} from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  CurrentTimeIndicator,
  Resources,
  DateNavigator,
  Toolbar,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { Grid, IconButton, styled } from "@mui/material"
import MoreIcon from "@mui/icons-material/MoreVert"
import Room from "@mui/icons-material/Room"
import moment from "moment"
import momentTZ from "moment-timezone"
import { resourcesData } from "../../constants/resources"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useNavigate } from "react-router-dom"

const PREFIX = "Demo"

const classes = {
  icon: `${PREFIX}-icon`,
  textCenter: `${PREFIX}-textCenter`,
  firstRoom: `${PREFIX}-firstRoom`,
  secondRoom: `${PREFIX}-secondRoom`,
  thirdRoom: `${PREFIX}-thirdRoom`,
  header: `${PREFIX}-header`,
  commandButton: `${PREFIX}-commandButton`,
}

const StyledAppointmentTooltipHeader = styled(AppointmentTooltip.Header)(
  ({ appointmentData }) => {
    return {
      backgroundImage: `url(${
        appointmentData?.lead?.f_image?.url || "/placeholder.png"
      } )`,
      height: "250px !important",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }
  }
)

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.commandButton}`]: {
    backgroundColor: "rgba(255,255,255,0.65)",
  },
}))

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.textCenter}`]: {
    textAlign: "center",
  },
}))

const StyledRoom = styled(Room)(({ theme: { palette } }) => ({
  [`&.${classes.icon}`]: {
    color: palette.action.active,
  },
}))

const StyledAppointmentTooltipCommandButton = styled(
  AppointmentTooltip.CommandButton
)(() => ({
  [`&.${classes.commandButton}`]: {
    backgroundColor: "rgba(255,255,255,0.65)",
  },
}))

const Content = ({ children, appointmentData, ...restProps }) => (
  <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
    <Grid container alignItems="center">
      <StyledGrid item xs={2} className={classes.textCenter}>
        <StyledRoom className={classes.icon} />
      </StyledGrid>
      <Grid item xs={10}>
        <span>{appointmentData?.dealership?.address}</span>
      </Grid>
    </Grid>
  </AppointmentTooltip.Content>
)

const CommandButton = ({ ...restProps }) => (
  <StyledAppointmentTooltipCommandButton
    {...restProps}
    className={classes.commandButton}
  />
)

const ExternalViewSwitcher = ({ currentViewName, onChange }) => (
  <ToggleButtonGroup
    color="primary"
    value={currentViewName}
    exclusive
    name="views"
    onChange={onChange}
    aria-label="Views"
    size="small"
  >
    <ToggleButton value="Week">Week</ToggleButton>
    {/* <ToggleButton value="Work Week">Work Week</ToggleButton> */}
    <ToggleButton value="Month">Month</ToggleButton>
  </ToggleButtonGroup>
)

export default function CalendarBox({ appointments }) {
  const navigate = useNavigate()

  const [currentViewName, setCurrentViewName] = React.useState("Month")
  const [curDate, setCurDate] = React.useState(new Date())
  const currentViewNameChange = (e, newVal) => {
    setCurrentViewName(newVal)
  }

  const Header = ({ children, appointmentData, ...restProps }) => (
    <StyledAppointmentTooltipHeader
      {...restProps}
      appointmentData={appointmentData}
    >
      <StyledIconButton
        onClick={() => navigate(`/leads/item/${appointmentData?.lead?._id}`)}
        className={classes.commandButton}
        size="large"
      >
        <RemoveRedEyeIcon />
      </StyledIconButton>
    </StyledAppointmentTooltipHeader>
  )

  const schedulerData = React.useMemo(() => {
    if (!appointments) return []
    return appointments?.map((a) => {
      return {
        ...a,
        startDate: momentTZ.tz(a?.start, "Europe/London").toISOString(true),
        endDate: momentTZ
          .tz(moment(a?.start).add(30, "m"), "Europe/London")
          .toISOString(true),
        title: a?.lead?.title,
      }
    })
  }, [appointments])

  const TimeTableCell = React.useCallback(
    React.memo(({ onDoubleClick, ...restProps }) => (
      <WeekView.TimeTableCell {...restProps} onDoubleClick={undefined} />
    )),
    []
  )

  return (
    <React.Fragment>
      <ExternalViewSwitcher
        currentViewName={currentViewName}
        onChange={currentViewNameChange}
      />
      <div style={{ height: 10 }}></div>
      <Paper>
        <Scheduler data={schedulerData}>
          <EditingState />
          <IntegratedEditing />
          <ViewState
            defaultCurrentDate={curDate}
            currentViewName={currentViewName}
            onCurrentDateChange={(currentDate) => setCurDate(currentDate)}
          />
          <WeekView
            startDayHour={7}
            endDayHour={21}
            timeTableCellComponent={TimeTableCell}
          />
          {/* <WeekView
            name="Work Week"
            excludedDays={[0, 6]}
            // startDayHour={7}
            // endDayHour={22}
            timeTableCellComponent={TimeTableCell}
          /> */}
          <MonthView />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments />
          <Resources
            data={[
              { fieldName: "status", title: "leads", instances: resourcesData },
            ]}
            mainResourceName="status"
          />
          <AppointmentTooltip
            headerComponent={Header}
            contentComponent={Content}
            commandButtonComponent={CommandButton}
            showCloseButton
          />
          <CurrentTimeIndicator
            shadePreviousAppointments={true}
            shadePreviousCells={true}
            updateInterval={10000}
          />
        </Scheduler>
      </Paper>
    </React.Fragment>
  )
}
