import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { PricesInputSimple } from "../createPage/PricesInput"
import PaperContainer from "../global/PaperContainer"
import Divider from "@mui/material/Divider"
import SelectWithCheckMark from "./inputs/SelectWithCheckMark"
import DealerActivity from "./dealerActivity"
import { Typography } from "@mui/material"
import { useState } from "react"
import { URL } from "../../config/apis"
import axios from "axios"
import { useParams } from "react-router-dom"
import SentToBox from "./SentToBox"
import useAuth from "../../context/Auth"
import AgnetBox from "../global/AgnetBox"
import { claimLead, unClaimLead } from "../../helppers/leadActions"
import FeedBackBox from "../global/FeedBackBox"
import PendingCounterOffers from "./PendingCounterOffers"
import LeadChatConversation from "../chat"
import AppointmentBox from "./appointmnets"
import PopUpDialogAccept from "../modals/PopUpDialogAccept"

export default function RightSide({
  lead,
  dActivity,
  setActivity,
  setLead,
  setDealerActivity,
  counters,
  setCounters,
  appointmnets,
  setAppointments,
}) {
  const { id } = useParams()
  const { user } = useAuth()
  const [prePrice, setPrePrice] = useState(lead?.prePrice || null)
  const [counterPrice, setCounterPrice] = useState(
    lead?.lastCounter?.amount || null
  )
  const [selected, setSelected] = useState([])
  const [selectedDealers, setSelectedDealers] = useState([])

  const [loading, setLoading] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)

  const [isShow, setIsShow] = useState(false)

  const sendCounterOffer = async () => {
    if (!counterPrice || counterPrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    if (counterPrice === lead?.lastCounter?.amount) {
      setErr("the same amount already sent, Please type different amount!")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/counter`,
        { amount: counterPrice },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastCounter: { ...res.data?.lastCounter },
        }))
        setActivity((prev) => [res.data.activity, ...prev])
        setCounters([])
      }
      setShow(true)
      setLoading(false)
      setTimeout(() => {
        setShow(false)
      }, 2000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 2000)
    }
  }

  const savePreNegPrice = async () => {
    if (!prePrice || prePrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (prePrice === lead?.prePrice) {
      setErr("the same amount already saved, Please type different amount!")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    setErr(null)
    setIsLoading(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/pre-neg`,
        { prePrice: prePrice },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          prePrice: res.data?.prePrice?.prePrice,
          preSentAt: res.data?.prePrice?.sentAt,
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setIsLoading(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setIsLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const sendToDealers = async () => {
    if (selected?.length === 0) {
      setErr("Please select at least one dealership")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    setErr(null)
    setSending(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/send-to-dealers`,
        { selectedDealers: selected },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastActivity: Date.now(),
          sentTo: [...prev?.sentTo, ...res.data.dealers],
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setSending(false)
      setSelected([])
      setSelectedDealers([])
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setSending(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const unSendToDealers = async (did) => {
    setErr(null)
    setSending(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/unsend`,
        { dealership: did },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastActivity: Date.now(),
          sentTo: [...prev?.sentTo?.filter((d) => d !== did)],
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setSending(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setSending(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          paddingY: "10px",
        }}
      >
        <PaperContainer py="0" variant="outlined" minHeight="max-content">
          {lead?.sellerPhone == null || lead?.sellerPhone === "" ? (
            <Stack
              alignItems="center"
              justifyContent={"center"}
              textAlign="center"
              px={2}
              py={3}
            >
              Seller's Number is required to start the conversation
            </Stack>
          ) : (
            <LeadChatConversation
              lead={lead}
              setLead={setLead}
              setActivty={setActivity}
            />
          )}
        </PaperContainer>
        <PaperContainer minHeight="max-content">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            {!lead?.acceptedBy &&
              !lead?.isLost &&
              !lead?.isTrash &&
              lead?.sentTo &&
              lead?.sentTo?.length > 0 &&
              !lead?.isDeal &&
              counters?.length === 0 && (
                <Stack
                  direction="row"
                  px={5}
                  py={1}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Button
                    disabled={loading}
                    onClick={() => setIsShow(true)}
                    variant="contained"
                    color="success"
                    fullWidth
                  >
                    Accept
                  </Button>
                </Stack>
              )}
            <Stack
              sx={{ width: "100%", paddingX: "20px" }}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {lead?.claimer ? (
                <>
                  {lead?.claimer === user?._id ? (
                    <>
                      <Button
                        onClick={() =>
                          unClaimLead(
                            lead?._id,
                            setLead,
                            setErr,
                            setClaiming,
                            setShow,
                            setActivity
                          )
                        }
                        disabled={claiming}
                        variant="contained"
                        color="error"
                      >
                        {claiming ? "UnClaiming..." : "UnClaim"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <span style={{ width: "100%", flexGrow: 1 }}>
                        Claimed by: <AgnetBox id={lead?.claimer} />
                      </span>
                      <Button
                        sx={{ flex: 0 }}
                        color="success"
                        onClick={() =>
                          claimLead(
                            lead?._id,
                            setLead,
                            setErr,
                            setClaiming,
                            setShow,
                            setActivity
                          )
                        }
                        disabled={claiming}
                        variant="contained"
                      >
                        Claim
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    color="success"
                    onClick={() =>
                      claimLead(
                        lead?._id,
                        setLead,
                        setErr,
                        setClaiming,
                        setShow,
                        setActivity
                      )
                    }
                    disabled={claiming}
                    variant="contained"
                  >
                    {claiming ? "Claiming..." : "Claim"}
                  </Button>
                </>
              )}
            </Stack>
            <Divider />
            <Stack
              sx={{ width: "100%", paddingX: "20px" }}
              direction="row"
              spacing={2}
            >
              <PricesInputSimple
                lable="Negotiated Price"
                value={prePrice}
                onValueChange={setPrePrice}
              />
              <Button
                onClick={savePreNegPrice}
                disabled={isLoading}
                variant="contained"
                sx={{ minWidth: "max-content", flex: 0 }}
              >
                {isLoading ? "Saving ..." : "Save"}
              </Button>
            </Stack>
            <Divider />
            {lead?.countersIn &&
              lead?.countersIn?.length > 0 &&
              !lead?.acceptedBy && (
                <>
                  <Stack
                    sx={{ width: "100%", paddingX: "20px" }}
                    direction="row"
                    spacing={2}
                  >
                    <PricesInputSimple
                      lable="Counter Offer"
                      value={counterPrice}
                      onValueChange={setCounterPrice}
                    />
                    <Button
                      onClick={sendCounterOffer}
                      disabled={loading}
                      variant="contained"
                      sx={{ minWidth: "max-content", flex: 0 }}
                    >
                      {loading ? "Sending..." : "Send"}
                    </Button>
                  </Stack>
                  <Divider />
                </>
              )}

            {!lead?.acceptedBy && !lead?.isLost && !lead?.isTrash && (
              <Stack
                sx={{ width: "100%", paddingX: "20px" }}
                direction="column"
                spacing={2}
              >
                {lead?.sentTo && lead?.sentTo?.length > 0 && (
                  <SentToBox
                    list={lead?.sentTo}
                    unSendToDealers={unSendToDealers}
                  />
                )}
                <SelectWithCheckMark
                  selected={selected}
                  setSelected={setSelected}
                  selectedDealers={selectedDealers}
                  setSelectedDealers={setSelectedDealers}
                  list={lead?.sentTo}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {selected?.length > 0 && (
                    <span style={{ fontSize: "13px" }}>
                      Selected: {selected?.length} Dealerships
                    </span>
                  )}
                  <Button
                    sx={{ width: "max-content !important", flex: 0 }}
                    variant="contained"
                    onClick={sendToDealers}
                    disabled={sending}
                  >
                    {sending ? "Sending..." : "Send"}
                  </Button>
                </div>
              </Stack>
            )}
          </Box>
        </PaperContainer>
        {counters && counters?.length > 0 && (
          <PaperContainer minHeight="max-content">
            <Typography
              sx={{
                display: "inline",
                fontWeight: 600,
                paddingX: "10px",
                paddingY: "10px",
              }}
              component="span"
              variant="body1"
              color="text.primary"
            >
              Incoming Counter Offers
            </Typography>
            <PendingCounterOffers
              counters={counters}
              setCounters={setCounters}
              setLead={setLead}
              setActivity={setActivity}
            />
          </PaperContainer>
        )}
        {appointmnets && appointmnets?.length > 0 && (
          <PaperContainer minHeight="max-content">
            <Typography
              sx={{
                display: "inline",
                fontWeight: 600,
                paddingX: "10px",
                paddingY: "10px",
              }}
              component="span"
              variant="body1"
              color="text.primary"
            >
              Appointments
            </Typography>
            <AppointmentBox
              appointmnets={appointmnets}
              setAppointments={setAppointments}
              setActivity={setActivity}
            />
          </PaperContainer>
        )}

        {dActivity && dActivity?.length > 0 && (
          <PaperContainer minHeight="max-content">
            <Typography
              sx={{
                display: "inline",
                fontWeight: 600,
                paddingX: "10px",
                paddingY: "10px",
              }}
              component="span"
              variant="body1"
              color="text.primary"
            >
              Dealers Activity
            </Typography>
            <DealerActivity
              dActivity={dActivity}
              setActivity={setActivity}
              setDealerActivity={setDealerActivity}
              setLead={setLead}
            />
          </PaperContainer>
        )}
      </Box>

      <FeedBackBox
        loading={loading || isLoading || sending}
        show={show}
        err={err}
      />

      {isShow && (
        <PopUpDialogAccept
          open={isShow}
          setOpen={setIsShow}
          loading={loading}
          setLoading={setLoading}
          setSuccess={setShow}
          setErr={setErr}
          setActivity={setActivity}
          setLead={setLead}
          lead={lead}
        />
      )}
    </>
  )
}
