import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material"
import React from "react"

const LineCheckBox = ({ title, val, onChange, name }) => {
  return (
    <Grid item xs={12} md={6}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <label>{title}</label>
        <RadioGroup
          onChange={onChange}
          value={val}
          row
          aria-labelledby={name}
          name={name}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </Stack>
    </Grid>
  )
}

export default function AutoCheckBox({ autoCheck, setLead }) {
  const onChange = (e) => {
    let { name, value } = e.target
    if (value === "true") {
      value = true
    } else if (value === "false") {
      value = false
    } else value = false

    setLead((prev) => ({
      ...prev,
      autoCheck: { ...prev.autoCheck, [name]: value },
    }))
  }
  return (
    <Grid container rowSpacing={0} columns={13} columnSpacing={5}>
      <LineCheckBox
        title={"Recorded as stolen?"}
        val={autoCheck?.stolen}
        name="stolen"
        onChange={onChange}
      />
      <Divider orientation="vertical" flexItem />
      <LineCheckBox
        title={"Recorded as scrapped?"}
        val={autoCheck?.scrapped}
        name="scrapped"
        onChange={onChange}
      />
      <LineCheckBox
        title={"Insurance write off?"}
        val={autoCheck?.writeOff}
        name="writeOff"
        onChange={onChange}
      />
      <Divider orientation="vertical" flexItem />
      <LineCheckBox
        title={"Imported?"}
        val={autoCheck?.imported}
        name="imported"
        onChange={onChange}
      />
      <LineCheckBox
        title={"Exported?"}
        val={autoCheck?.exported}
        name="exported"
        onChange={onChange}
      />
      <Divider orientation="vertical" flexItem />
      <LineCheckBox
        title={"Original Number Plate Changed?"}
        val={autoCheck?.plateChanged}
        name="plateChanged"
        onChange={onChange}
      />
      <LineCheckBox
        title={"Original Colour Changed?"}
        val={autoCheck?.colorChanged}
        name="colorChanged"
        onChange={onChange}
      />
      <Divider orientation="vertical" flexItem />
    </Grid>
  )
}
