import React from "react"
import moment from "moment"
import momentTz from "moment-timezone"
import Typography from "@mui/material/Typography"
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { DashboardContext } from "../../pages/Dashboard"

export default function ListItems({ item, Icon, color = "#e62727" }) {
  const { selected, setSelected } = React.useContext(DashboardContext)

  return (
    <ListItemButton
      className="db-list"
      alignItems="flex-start"
      sx={{
        bgcolor: item?.lead?._id === selected ? "#3c3b6e59" : "inherit",
      }}
      onClick={() => {
        setSelected((prev) => {
          if (prev === item?.lead?._id) {
            return null
          } else {
            return item?.lead?._id
          }
        })
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: color,
            width: 30,
            height: 30,
          }}
        >
          <Icon fontSize="small" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{ display: "inline" }}
            component="h2"
            variant="body2"
            fontWeight={500}
          >
            {item?.lead?.title}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              sx={{
                display: "inline",
                fontSize: 12,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              <b>
                {momentTz.tz(item?.start, item?.tz).format("MM/DD/YY @ h:mm A")}
              </b>{" "}
              - {item?.dealership?.dname}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItemButton>
  )
}
