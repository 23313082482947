export const handleDrag = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export const handleDragIn = (e, setDragCounter, setDrag) => {
  e.preventDefault()
  e.stopPropagation()
  setDragCounter((prev) => prev + 1)
  setDrag(true)
}

export const handleDragOut = (e, setDragCounter, setDrag) => {
  e.preventDefault()
  e.stopPropagation()
  setDragCounter((prev) => {
    if (prev - 1 === 0) {
      setDrag(false)
    }
    return prev - 1
  })
}
