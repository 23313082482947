import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { ButtonGroup, IconButton, Stack, styled } from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import CloseIcon from "@mui/icons-material/Close"

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={false} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#f0f0fd",
  margin: 0,
}))

export default function AppointmentsBox({
  label,
  movePge,
  page = undefined,
  total = undefined,
  isClose = false,
  isBtn = true,
  children,
}) {
  return (
    <Accordion expanded={true}>
      <AccordionSummary
        expandIcon={false}
        aria-controls={`${label}-content`}
        id={`${label}-content`}
      >
        <Stack
          sx={{ width: "100%" }}
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>{label}</Typography>
          {isBtn && (
            <>
              {isClose ? (
                <IconButton
                  color="primary"
                  aria-label="close"
                  component="label"
                  onClick={() => movePge(null)}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <ButtonGroup
                  // variant="contained"
                  aria-label="outlined primary button group"
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => movePge(-1)}
                    disabled={page === 1}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => movePge(1)}
                    disabled={page === total}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </ButtonGroup>
              )}
            </>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
