import * as React from "react"
import { Divider, Stack, Typography } from "@mui/material"

const LineCheckBox = ({ title, val }) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent={"space-between"}
    >
      <Typography variant="h6" fontWeight={"500"}>
        {title}
      </Typography>
      <Typography
        variant="h6"
        color={val === false ? "green" : val === true ? "red" : "inherit"}
        fontWeight={"700"}
      >
        {val == null ? "N/A" : val === true ? "Not Passed" : "Passed"}
      </Typography>
    </Stack>
  )
}

export default function AutoCheckData({ autoCheck }) {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">AutoCheck</Typography>
      <Divider />
      <Stack>
        <LineCheckBox title={"Recorded as stolen?"} val={autoCheck?.stolen} />
        <Divider orientation="vertical" flexItem />
        <LineCheckBox
          title={"Recorded as scrapped?"}
          val={autoCheck?.scrapped}
        />
        <LineCheckBox
          title={"Insurance write off?"}
          val={autoCheck?.writeOff}
        />
        <Divider orientation="vertical" flexItem />
        <LineCheckBox title={"Imported?"} val={autoCheck?.imported} />
        <LineCheckBox title={"Exported?"} val={autoCheck?.exported} />
        <Divider orientation="vertical" flexItem />
        <LineCheckBox
          title={"Original Number Plate Changed?"}
          val={autoCheck?.plateChanged}
        />
        <LineCheckBox
          title={"Original Colour Changed?"}
          val={autoCheck?.colorChanged}
        />
      </Stack>
    </Stack>
  )
}
