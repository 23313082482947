import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

export default function SimpleModal({
  open,
  setOpen,
  setTitle,
  children,
  maxWidth = "lg",
}) {
  const handleClose = () => {
    setOpen(false)
    setTitle(null)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
