import { MenuItem, Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import axios from "axios"
import { useEffect, useState } from "react"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import Pagination from "@mui/material/Pagination"
import { useMemo } from "react"
import SkeletonCard from "../components/global/SkeletonCard"
import SelectMenu from "../components/createPage/SelectMenu"
import useData from "../context/Data"
import CountersTable from "../components/tables/CountersTable"
import TableLoadingData from "../components/global/TableLoadingData"

const pageSize = 20

export default function CountersPage() {
  const { dealerships } = useData()
  const cash = JSON.parse(sessionStorage.getItem("counterPage"))

  const [leads, setLeads] = useState([])
  const [err, setErr] = useState(null)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [show, setShow] = useState(false)

  const [page, setPage] = useState(cash?.page || 1)
  const [dealer, setDealer] = useState(cash?.dealer || "All")

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setFetching(true)
    try {
      const res = await axios.get(
        `${URL}/leads/counters?page=${page}&dealer=${dealer}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data?.leads)
      setCount(res.data?.count)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem("counterPage", JSON.stringify({ page, dealer }))
  }, [page, dealer])

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  if (loading) {
    return <SkeletonCard />
  }

  return (
    <>
      <Container sx={{ position: "relative" }} maxWidth="100%">
        {fetching && <TableLoadingData />}
        <Stack
          direction={"row"}
          justifyContent="start"
          sx={{ mb: 2 }}
          spacing={2}
        >
          <SelectMenu
            handleChange={(v) => {
              setDealer(v)
              setPage(1)
            }}
            label={"Dealerships"}
            name={"dealerships"}
            value={dealer}
            options={dealerships}
            sx={{ minWidth: 150 }}
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            {dealerships &&
              dealerships?.map((u, i) => (
                <MenuItem key={u?._id + i} value={u?._id}>
                  {u?.dname}
                </MenuItem>
              ))}
          </SelectMenu>
        </Stack>
        <Box>
          {leads?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                No Counters Found
              </Typography>
            </Stack>
          ) : (
            <CountersTable data={leads} />
          )}
        </Box>
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
      <FeedBackBox loading={isLoading} show={show} err={error} />
    </>
  )
}
