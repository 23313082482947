import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { PricesInputSimple } from "../createPage/PricesInput"
import { Chip, FormControlLabel, MenuItem, Stack, Switch } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { URL } from "../../config/apis"
import axios from "axios"
import useData from "../../context/Data"
import SelectMenu from "../createPage/SelectMenu"
import { timePicker } from "../../constants/timePicker"
import { selectTime } from "../../helppers/selectTime"
import moment from "moment"

export default function PopUpDialogAppointment({
  open,
  setOpen,
  setLead,
  setActivity,
  id,
  loading,
  setLoading,
  setErr,
  setShow,
}) {
  const { dealerships } = useData()

  const [agreedPrice, setAgreedPrice] = React.useState(null)
  const [date, setDate] = React.useState(null)
  const [time, setTime] = React.useState(null)
  const [dealer, setDealer] = React.useState(null)
  const [address, setAddress] = React.useState("")
  const [isPickUp, setIsPickUp] = React.useState(false)

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChangeSelect = (value, name) => {
    setDealer(value)
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    // if (!agreedPrice || agreedPrice === 0) {
    //   setErr("Please Type valid amount")
    //   return setTimeout(() => {
    //     setErr(null)
    //   }, 3000)
    //}
    if (!id) {
      setErr("Please Select lead")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!dealer) {
      setErr("Please Select Dealership")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!date || !time) {
      setErr("Please Select Date & time")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    const d = dealerships?.find((d) => d?._id === dealer)
    const data = {
      agreedPrice,
      start: selectTime(d?.tz, date, time),
      dealership: dealer,
      lead: id,
      isPickUp,
      address,
    }

    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.post(`${URL}/calendar/appointment`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          agreedPrice: agreedPrice,
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setLoading(false)
      setAgreedPrice(null)
      setDate(null)
      setTime(null)
      setDealer(null)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle>Create Appointment</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <DialogContentText>
            Please Select Dealership & Add Agreed Price and the Appointment Date
            / time
          </DialogContentText>
          <Stack direction={"row"} spacing={2}>
            <SelectMenu
              sx={{ minWidth: 150, width: "100%" }}
              label="Dealership"
              options={dealerships}
              value={dealer}
              handleChange={handleChangeSelect}
              name={"dealer"}
            >
              {dealerships?.map((o, i) => (
                <MenuItem key={o?._id + i} value={o?._id}>
                  {o?.dname}
                </MenuItem>
              ))}
            </SelectMenu>
            <FormControlLabel
              control={
                <Switch
                  checked={isPickUp}
                  onChange={(e, checked) => setIsPickUp(checked)}
                />
              }
              label="Pickup"
            />
          </Stack>
          {dealer && (
            <>
              <Stack direction={"row"} spacing={2}>
                <PricesInputSimple
                  lable="Agreed Price"
                  value={agreedPrice}
                  onValueChange={setAgreedPrice}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Appointment Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(moment(newValue).format("MM/DD/YYYY"))
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              {dealer && date && isPickUp && (
                <TextField
                  id="address"
                  label="Pickup Address"
                  variant="outlined"
                  value={address}
                  size="small"
                  onChange={({ target }) => setAddress(target.value)}
                />
              )}
              {date && (
                <Stack spacing={2}>
                  <div>Select Time</div>
                  <Stack direction={"row"} gap={1} flexWrap="wrap">
                    {timePicker.map((item, i) => (
                      <Chip
                        key={`app-time-${item.format + i}`}
                        label={item.start}
                        color={time === item.format ? "primary" : "default"}
                        onClick={() => setTime(item.format)}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        {dealer && date && time && id && (
          <Button disabled={loading} variant="contained" onClick={submitDeal}>
            {loading ? "Submiting..." : "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
