export const resourcesData = [
  {
    text: "Pending",
    id: "Pending",
    color: "#42a5f5",
  },
  {
    text: "Deal",
    id: "Deal",
    color: "#4caf50",
  },
  {
    text: "Lost",
    id: "Lost",
    color: "#d32f2f",
  },
]
