import * as React from "react"
import { Box, Divider, Stack, Typography } from "@mui/material"

export default function ConditionReport({ html }) {
  return (
    <Stack spacing={2} >
      <Typography variant="h5">Condition Report</Typography>
      <Divider />
      <Box>
        <div dangerouslySetInnerHTML={{ __html: html?.html }}></div>
      </Box>
    </Stack>
  )
}
