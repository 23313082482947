import { Chip, Stack } from "@mui/material"
import React from "react"

export default function CheckMarkSelect({
  data,
  title,
  setCreiteria,
  xkey,
  selected,
}) {
  const handleSelect = (val) => {
    setCreiteria((prev) => {
      const t = prev[xkey]
      if (!t) {
        return { ...prev, [xkey]: [val] }
      }
      if (t?.includes(val)) {
        return { ...prev, [xkey]: [...t?.filter((v) => v !== val)] }
      } else {
        return { ...prev, [xkey]: [...t, val] }
      }
    })
  }
  return (
    <Stack direction={'row'} sx={{px: 2}} alignItems='center' spacing={2}>
      <div>{title}</div>
      <Stack direction={"row"} gap={1} flexWrap="wrap">
        {data?.map((item, i) => (
          <Chip
            key={`${item?.name + i}`}
            label={item?.name}
            color={selected?.includes(item?.name) ? "primary" : "default"}
            onClick={() => handleSelect(item?.name)}
          />
        ))}
      </Stack>
    </Stack>
  )
}
