import HomeIcon from "@mui/icons-material/Home"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave"
import FolderCopyIcon from "@mui/icons-material/FolderCopy"
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import DeleteIcon from "@mui/icons-material/Delete"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import DashboardIcon from "@mui/icons-material/Dashboard"

export const navMenu = [
  {
    lable: "Dashboard",
    icon: <DashboardIcon />,
    href: "/",
  },
  {
    lable: "Create",
    icon: <AddCircleIcon />,
    href: "/new",
  },

  {
    lable: "Leads",
    icon: <FolderCopyIcon />,
    href: "/leads",
  },
  {
    lable: "Dealerships",
    icon: <EmojiTransportationIcon />,
    href: "/dealerships",
  },

  {
    lable: "Users",
    icon: <PeopleAltIcon />,
    href: "/users",
  },
]

export const botMenu = [
  {
    lable: "Accepted",
    icon: <MoveToInboxIcon />,
    href: "/accepted",
  },
  {
    lable: "Counters",
    icon: <ImportExportIcon />,
    href: "/counters",
  },
  {
    lable: "Appointments",
    icon: <CalendarMonthIcon />,
    href: "/appointments",
  },
  {
    lable: "Deals",
    icon: <RequestQuoteIcon />,
    href: "/deals",
  },
  {
    lable: "Lost",
    icon: <HighlightOffIcon />,
    href: "/lost",
  },
  {
    lable: "Trash",
    icon: <DeleteIcon />,
    href: "/trash",
  },
]
