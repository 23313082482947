import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { PricesInputSimple } from "../createPage/PricesInput"
import { MenuItem, Stack } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { URL } from "../../config/apis"
import axios from "axios"
import useData from "../../context/Data"
import SelectMenu from "../createPage/SelectMenu"

export default function PopUpDialogDeal({
  open,
  setOpen,
  setLead,
  setActivity,
  id,
  loading,
  setLoading,
  setErr,
  setShow,
}) {
  const { dealerships } = useData()

  const [purchasePrice, setPurchasePrice] = React.useState(null)
  const [purchaseAt, setPurchaseAt] = React.useState(null)
  const [dealer, setDealer] = React.useState(null)

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChangeSelect = (value, name) => {
    setDealer(value)
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    if (!purchasePrice || purchasePrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!purchaseAt) {
      setErr("Please Select purchased date")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!dealer) {
      setErr("Please Select Dealership")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    const data = {
      purchaseAt,
      purchasePrice,
      dealer,
    }
    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.put(`${URL}/leads/item/${id}/deal`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          purchasePrice: purchasePrice,
          purchaseAt: purchaseAt,
          winner: dealer,
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setLoading(false)
      setPurchasePrice(null)
      setPurchaseAt(null)
      setDealer(null)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>Deal</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <DialogContentText>
            Please Select Dealership & Add Purchased Price and Date
          </DialogContentText>
          <SelectMenu
            sx={{ minWidth: 150 }}
            label="Dealership"
            options={dealerships}
            value={dealer}
            handleChange={handleChangeSelect}
            name={"dealer"}
          >
            {dealerships?.map((o, i) => (
              <MenuItem key={o?._id + i} value={o?._id}>
                {o?.dname}
              </MenuItem>
            ))}
          </SelectMenu>
          <Stack direction={"row"} spacing={2}>
            <PricesInputSimple
              lable="Purchased Price"
              value={purchasePrice}
              onValueChange={setPurchasePrice}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Purchased Date"
                value={purchaseAt}
                onChange={(newValue) => {
                  setPurchaseAt(new Date(newValue).toISOString())
                }}
                renderInput={(params) => (
                  <TextField fullWidth size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={submitDeal}>
          {loading ? "Submiting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
