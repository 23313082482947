import * as React from "react"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"

export default function ResCard({ item, setAnchorEl, setOpen }) {
  const navigate = useNavigate()

  return (
    <Card
      sx={{ display: "flex", cursor: "pointer" }}
      onClick={() => {
        navigate(`/leads/item/${item?._id}`)
        setAnchorEl(null)
        setOpen(false)
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 120, height: 100, flex: "none" }}
        image={item?.f_image?.url}
        alt={item?.title}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            component="div"
            variant="subtitle1"
            fontWeight={600}
          >
            {item?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {item?.vrm}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {item?.creator?.fname}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
