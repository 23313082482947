import React from "react"
import { DashboardContext } from "../../pages/Dashboard"
import AppointmentsBox from "./AppointmentsBox"
import LatestUpdates from "./LatestUpdates"
import SnapShotView from "./SnapShotView"

export default function RightSide() {
  const { selected, setSelected } = React.useContext(DashboardContext)

  if (selected == null) {
    return (
      <>
        <AppointmentsBox label={`Latest Updates`} isBtn={false}>
          <LatestUpdates />
        </AppointmentsBox>
      </>
    )
  }

  return (
    <>
      <SnapShotView />
    </>
  )
}
