import { Box, Container } from "@mui/material"
import axios from "axios"
import React, { useEffect } from "react"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ProfileCard from "../components/profiles/ProfileCard"
import { URL } from "../config/apis"

export default function AgentPage() {
  const navigate = useNavigate()
  const { id } = useParams()

  const [agent, setAgent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)

  const getDealerships = async () => {
    try {
      const res = await axios.get(`${URL}/agents/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAgent(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getDealerships()
  }, [])

  if (loading) {
    return <>Loading...</>
  }

  if (!loading && err) {
    return <>{err}</>
  }

  return (
    <>
      <Container maxWidth="100%">
        <ProfileCard user={agent} />
      </Container>
    </>
  )
}
