import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Link } from "react-router-dom"

export default function Breadcrumb({ title }) {
  const breadcrumbs = [
    <Link to="/leads" style={{color: 'inherit', textDecoration: 'none' }}>Leads</Link>,

    <Typography key="2" color="text.primary">
      {title}
    </Typography>,
  ]

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  )
}
