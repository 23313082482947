import { Grid, Stack, Tooltip } from "@mui/material"
import React from "react"

export default function DataLine({ title, value, sm = 2, tooltip = null }) {
  return (
    <Grid item sm={sm}>
      <Stack>
        {tooltip ? (
          <Tooltip placement="top" title={tooltip}>
            <strong style={{ textDecoration: "underline", cursor: "pointer" }}>
              {title}
            </strong>
          </Tooltip>
        ) : (
          <strong>{title}</strong>
        )}
        <span>{value}</span>
      </Stack>
    </Grid>
  )
}
