import { Container, Divider, Stack } from "@mui/material"
import React from "react"
import EditProfileSection from "../components/profiles/EditProfileSection"
import ProfileCard from "../components/profiles/ProfileCard"
import useAuth from "../context/Auth"

export default function ProfilePage() {
  const { user } = useAuth()

  return (
    <>
      <Container maxWidth="100%" sx={{ display: "flex" }}>
        <Stack direction={"row"} sx={ {width: '100%'}} spacing={2}>
          <ProfileCard user={user} />
          <Divider variant="middle" orientation="vertical" flexItem />
          <EditProfileSection />
        </Stack>
      </Container>
    </>
  )
}
