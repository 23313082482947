import { Button, Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LeadsBox } from "../components/boxes"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import Pagination from "@mui/material/Pagination"
import { useMemo } from "react"
import LoadingBox from "../components/global/LoadingBox"
import SkeletonCard from "../components/global/SkeletonCard"
import LeadsFilters from "../components/filters/LeadsFilters"

const pageSize = 20

export default function Home() {
  const navigate = useNavigate()
  const cash = JSON.parse(sessionStorage.getItem("leadsPage"))

  const [leads, setLeads] = useState([])
  const [err, setErr] = useState(null)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [show, setShow] = useState(false)

  const [page, setPage] = useState(cash?.page || 1)
  const [agent, setAgent] = useState(cash?.agent || "All")
  const [claimer, setClaimer] = useState(cash?.claimer || "All")
  const [make, setMake] = useState(cash?.make || "All")

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setFetching(true)
    setLoading(true)
    try {
      const res = await axios.post(
        `${URL}/leads/get?page=${page}`,
        { agent, claimer, make },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data?.leads)
      setCount(res.data?.count)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem(
      "leadsPage",
      JSON.stringify({ page, agent, claimer, make })
    )
  }, [page, agent, claimer, make])

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  if (loading) {
    return <SkeletonCard />
  }

  return (
    <>
      <Container maxWidth="100%">
        <LeadsFilters
          agent={agent}
          setAgent={setAgent}
          setPage={setPage}
          count={count}
          claimer={claimer}
          setClaimer={setClaimer}
          setMake={setMake}
          make={make}
        />
        <Box>
          {leads?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                No Leads Found
              </Typography>
              <Stack
                alignItems={"center"}
                justifyContent="center"
                direction={"row"}
                spacing={2}
              >
                <Button onClick={() => navigate(`/new`)} variant="contained">
                  Create new Lead
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Grid container spacing={3}>
              {leads?.map((lead, i) => (
                <Grid key={lead?._id + i} xs={12} md={6} lg={4} xl={3} item>
                  <LeadsBox
                    lead={lead}
                    setErr={setError}
                    setLeads={setLeads}
                    getLeads={getLeads}
                    setLoading={setIsLoading}
                    setShow={setShow}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <br />
        <Stack
          direction={"row"}
          justifyContent="center"
          sx={{ mt: 2 }}
          spacing={2}
        >
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            showFirstButton
            showLastButton
          />
        </Stack>
      </Container>
      <FeedBackBox loading={isLoading} show={show} err={error} />
    </>
  )
}
