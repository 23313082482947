export const timePicker = [
  { start: "7:00 AM", end: "7:30 AM", format: "7:00" },
  { start: "7:30 AM", end: "8:00 AM", format: "7:30" },
  { start: "8:00 AM", end: "8:30 AM", format: "8:00" },
  { start: "8:30 AM", end: "9:00 AM", format: "8:30" },
  { start: "9:00 AM", end: "9:30 AM", format: "9:00" },
  { start: "9:30 AM", end: "10:00 AM", format: "9:30" },
  { start: "10:00 AM", end: "10:30 AM", format: "10:00" },
  { start: "10:30 AM", end: "11:00 AM", format: "10:30" },
  { start: "11:00 AM", end: "11:30 AM", format: "11:00" },
  { start: "11:30 AM", end: "12:00 AM", format: "11:30" },
  { start: "12:00 PM", end: "12:30 PM", format: "12:00" },
  { start: "12:30 PM", end: "1:00 PM", format: "12:30" },
  { start: "1:00 PM", end: "1:30 PM", format: "13:00" },
  { start: "1:30 PM", end: "2:00 PM", format: "13:30" },
  { start: "2:00 PM", end: "2:30 PM", format: "14:00" },
  { start: "2:30 PM", end: "3:00 PM", format: "14:30" },
  { start: "3:00 PM", end: "3:30 PM", format: "15:00" },
  { start: "3:30 PM", end: "4:00 PM", format: "15:30" },
  { start: "4:00 PM", end: "4:30 PM", format: "16:00" },
  { start: "4:30 PM", end: "5:00 PM", format: "16:30" },
  { start: "5:00 PM", end: "5:30 PM", format: "17:00" },
  { start: "5:30 PM", end: "6:00 PM", format: "17:30" },
  { start: "6:00 PM", end: "6:30 PM", format: "18:00" },
  { start: "6:30 PM", end: "7:00 PM", format: "18:30" },
  { start: "7:00 PM", end: "7:30 PM", format: "19:00" },
  { start: "7:30 PM", end: "8:00 PM", format: "19:30" },
  { start: "8:00 PM", end: "8:30 PM", format: "20:00" },
  { start: "8:30 PM", end: "9:00 PM", format: "20:30" },
  { start: "9:00 PM", end: "9:30 PM", format: "21:00" },
  { start: "9:30 PM", end: "10:00 PM", format: "21:30" },
]
