import { Button, Stack } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import useTwilioChat from "../../context/TwilioChat"
import { updateLead } from "../../helppers/apiCalls/chat"
import ChatBox from "./ChatBox"
import TextsmsIcon from "@mui/icons-material/Textsms"
import useData from "../../context/Data"

export default function LeadChatConversation({ lead, setLead, setActivty }) {
  const { client } = useTwilioChat()
  const { agents } = useData()
  const msgRef = React.useRef()

  const scrollToView = () => {
    if (msgRef.current == null) return
    //msgRef?.current?.scrollIntoView({ behavior: "smooth" })
    //window.scroll(0, 0)
    const sh = msgRef.current.scrollHeight + 100
    // msgRef.current.scrollTop = msgRef.current.scrollHeight + 10
    msgRef.current.scrollTo(0, sh)
  }

  const [conversation, setConversation] = useState(null)
  const [err, setErr] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [parts, setParts] = useState([])
  const [unread, setUnread] = useState(0)

  const getMsg = async (conversation) => {
    if (!conversation) return
    setLoading(true)
    const allmessages = await conversation?.getMessages()
    const allParts = await conversation?.getParticipants()
    setMessages(allmessages?.items || [])
    setParts(allParts || [])
    setLoading(false)
    scrollToView()
  }

  const sellerJoin = async (conversation) => {
    if (!conversation) return
    const mytn = `+442033222405`
    const psn = `+44${lead?.sellerPhone}`
    const attributes = {
      name: lead?.sellerName || `Seller-${lead?.sellerPhone}`,
    }
    try {
      const part = await conversation.addNonChatParticipant(
        mytn,
        psn,
        attributes
      )
      console.log("seller joined")
    } catch (error) {
      console.log(error)
      console.log("seller not joined")
    }
  }

  useEffect(() => {
    if (client && lead?.csid) {
      const getConv = async () => {
        try {
          const conv = await client.getConversationBySid(lead?.csid)
          if (conv) {
            setConversation(conv)
            await getMsg(conv)
          }
        } catch (error) {
          console.log(error)
        }
      }
      getConv()
    }
  }, [client, lead?.csid])

  const AddToChat = async (user, conversation = conversation) => {
    const attributes = {
      id: user?._id,
      fname: user?.fname,
      email: user?.email,
    }
    try {
      const part = await conversation?.add(user?.email, attributes)
      setParts((prev) => [...prev, part])
      console.log(`${user?.fname} added`)
    } catch (error) {
      console.log(`${user?.fname} not added`)
      console.log(error)
    }
  }

  const CreateConv = async () => {
    setIsLoading(true)

    try {
      const conv = await client.createConversation({
        friendlyName: `${lead?.sellerName} - ${lead?.title}`,
        uniqueName: lead?._id,
        attributes: {
          id: lead?._id,
          title: lead?.title,
          image: lead?.f_image?.url,
        },
      })

      const res = await updateLead(lead?._id, { csid: conv?.sid })
      if (res.ok) {
        setLead((prev) => ({ ...prev, csid: conv?.sid }))
        setActivty((prev) => [res.activity, ...prev])
      }
      // await conv.join()
      setConversation(conv)
      await sellerJoin(conv)
      if (agents?.length > 0) {
        await Promise.all(
          agents?.map((u) => {
            return AddToChat(u, conv)
          })
        )
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      setErr(error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const deleteConv = async () => {
    try {
      await conversation?.delete()
      const res = await updateLead(lead?._id, { csid: null })
      setLead((prev) => ({ ...prev, csid: null }))
      setActivty((prev) => [res.activity, ...prev])
      setConversation(null)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (conversation) {
      const connnectToConv = async () => {
        try {
          conversation.on("messageAdded", (message) => {
            if (message?.conversation?.sid === conversation?.sid) {
              const isExist = messages.find((m) => m.sid === message.sid)
              if (!isExist) {
                setMessages((prev) => {
                  const ishere = prev.find((p) => p.sid === message.sid)
                  if (!ishere) {
                    return [...prev, message]
                  } else {
                    return [...prev]
                  }
                })
              }
              scrollToView()
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
      connnectToConv()
    }
  }, [conversation])

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 10px",
        }}
      >
        Starting the conversation ...
      </div>
    )
  }
  if (err) {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 10px",
        }}
      >
        {err}
      </div>
    )
  }

  return (
    <div>
      {conversation ? (
        <>
          <ChatBox
            conv={conversation}
            messages={messages}
            setMessages={setMessages}
            deleteConv={deleteConv}
            ref={msgRef}
            parts={parts}
          />
        </>
      ) : (
        <Stack alignItems={"center"} justifyContent="center" p={2}>
          <Button
            onClick={CreateConv}
            variant="contained"
            color="primary"
            startIcon={<TextsmsIcon />}
          >
            Start Conversation
          </Button>
        </Stack>
      )}
    </div>
  )
}
