import * as React from "react"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import CounterOffer from "./CounterOffer"
import DefaultView from "./DefaultView"
import AcceptedView from "./AcceptedView"

export default function DealerActivity({ dActivity }) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {dActivity &&
        dActivity?.map((item, i) => (
          <>
            {item?.type === "Counter" ? (
              <CounterOffer key={item?._id + i} item={item} />
            ) : item?.type === "Accept" || item?.type === "Accepted Counter" ? (
              <AcceptedView key={item?._id + i} item={item} />
            ) : (
              <DefaultView key={item?._id + i} item={item} />
            )}
            {dActivity?.length - 1 !== i && (
              <Divider key={item?._id + i} variant="inset" component="li" />
            )}
          </>
        ))}
    </List>
  )
}
