import * as React from "react"
import { styled } from "@mui/material/styles"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import EditIcon from "@mui/icons-material/Edit"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb"
import DeleteIcon from "@mui/icons-material/Delete"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import PostAddIcon from "@mui/icons-material/PostAdd"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"
import { useNavigate } from "react-router-dom"
import { moveToLostSold, moveToTrash } from "../../helppers/leadActions"
import PopUpDialogDeal from "../modals/PopUpDialogDeal"
import FeedBackBox from "../global/FeedBackBox"
import PopUpDialogAppointment from "../modals/PopUpDialogAppointment"

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  // position: "absolute",
  //   "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
  //     bottom: theme.spacing(2),
  //     right: theme.spacing(2),
  //   },
  //   "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
  //     top: theme.spacing(2),
  //     left: theme.spacing(2),
  //   },
}))
const x = 1
export default function ActionsGroupBtn({ lead, setLead, setActivity }) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [isShow, setIsShow] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const handleOpen = (event) => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        hidden={false}
        icon={<EditIcon sx={{ fontSize: "1rem" }} />}
        direction="left"
        open={open}
        onClick={handleOpen}
      >
        <SpeedDialAction
          onClick={() => navigate(`/leads/edit/${lead?._id}`)}
          icon={<EditIcon />}
          tooltipTitle="Edit"
        />
        {lead?.acceptedBy &&
          !lead?.isDeal &&
          !lead?.isLost &&
          !lead?.isTrash && (
            <SpeedDialAction
              onClick={() => setIsOpen(true)}
              icon={<MonetizationOnIcon />}
              tooltipTitle="Deal"
            />
          )}
        {!lead?.isDeal && (
          <SpeedDialAction
            onClick={() =>
              moveToLostSold(
                lead?._id,
                setLead,
                setErr,
                setLoading,
                setShow,
                lead?.isLost,
                setActivity
              )
            }
            icon={<DoNotDisturbIcon />}
            tooltipTitle={
              lead?.isLost ? "Recover from Lost/Sold" : "Lost / Sold"
            }
          />
        )}
        {!lead?.isDeal && (
          <SpeedDialAction
            onClick={() =>
              moveToTrash(
                lead?._id,
                setLead,
                setErr,
                setLoading,
                setShow,
                lead?.isTrash,
                setActivity
              )
            }
            icon={<DeleteIcon />}
            tooltipTitle={lead?.isTrash ? "Recover from Trash" : "Trash"}
          />
        )}
        {lead?.acceptedBy &&
          !lead?.isDeal &&
          !lead?.isLost &&
          !lead?.isTrash && (
            <SpeedDialAction
              onClick={() => setIsShow(true)}
              icon={<InsertInvitationIcon />}
              tooltipTitle="Appointment"
            />
          )}
        {/* <SpeedDialAction
          icon={
            lead?.claimedBy === x ? <PlaylistRemoveIcon /> : <PostAddIcon />
          }
          tooltipTitle={
            lead?.claimedBy === x
              ? "Unclaim"
              : lead?.claimed
              ? "Claim"
              : "Claim"
          }
        /> */}
      </StyledSpeedDial>
      <PopUpDialogDeal
        open={isOpen}
        setActivity={setActivity}
        setLead={setLead}
        setOpen={setIsOpen}
        id={lead?._id}
        loading={loading}
        setErr={setErr}
        setShow={setShow}
        setLoading={setLoading}
      />
      <PopUpDialogAppointment
        open={isShow}
        setActivity={setActivity}
        setLead={setLead}
        setOpen={setIsShow}
        id={lead?._id}
        loading={loading}
        setErr={setErr}
        setShow={setShow}
        setLoading={setLoading}
      />

      <FeedBackBox loading={loading} show={show} err={err} />
    </>
  )
}
