import { Backdrop, CircularProgress } from "@mui/material"
import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import LoadingBox from "../components/global/LoadingBox"
import { URL } from "../config/apis"
const INITIAL = {
  user: null,
  loading: true,
  error: null,
}

const AuthContext = createContext(INITIAL)

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const getCurrentUser = async () => {
    try {
      const res = await axios.get(`${URL}/auth/status`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setUser(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } catch (error) {
      setError(error.response?.data?.message)
      setUser(null)
      setLoading(false)
    }
  }

  const logoutUser = async () => {
    setIsLoading(true)
    const res = await axios.post(
      `${URL}/auth/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    setTimeout(() => {
      window.location.replace("/login")
    }, 1000)
  }

  useEffect(() => {
    if (!user) {
      getCurrentUser()
    }
  }, [user])

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      setUser,
      setError,
      setLoading,
      logoutUser,
    }),
    [user, loading, error]
  )

  return (
    <AuthContext.Provider value={memoedValue}>
      {loading ? <LoadingBox /> : children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
