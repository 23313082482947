import { useCallback, useEffect, useState } from "react"
import Quill from "quill"
import "quill/dist/quill.snow.css"
import { Paper } from "@mui/material"

const TOOLBAR_OPTIONS = [
  [{ header: [3, 4, 5, 6, false] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  [{ align: [] }],
  ["clean"],
]

const INITIAL_STATE =
  '{"ops":[{"insert":"Exterior:"},{"attributes":{"header":3},"insert":"\\n"},{"insert":"Interior:"},{"attributes":{"header":3},"insert":"\\n"},{"insert":"Tires:"},{"attributes":{"header":3},"insert":"\\n"},{"insert":"Mechanically:"},{"attributes":{"header":3},"insert":"\\n"}]}'

export default function TextEditor({ value, setLead }) {
  const [quill, setQuill] = useState()

  useEffect(() => {
    if (quill == null) return
    const val = JSON.parse(value?.delta || INITIAL_STATE)
    quill.setContents(val)
    quill.enable()
  }, [quill])

  useEffect(() => {
    if (quill == null) return

    const handler = () => {
      const delta = quill.getContents()
      const html = quill.root.innerHTML

      setLead((prev) => ({
        ...prev,
        condition: {
          delta: JSON.stringify(delta),
          html,
        },
      }))
    }

    quill.on("text-change", handler)

    return () => {
      quill.off("text-change", handler)
    }
  }, [quill])

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return

    wrapper.innerHTML = ""
    const editor = document.createElement("div")
    wrapper.append(editor)
    const q = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
    })
    q.disable()
    q.setText("Loading...")
    setQuill(q)
  }, [])
  return (
    <Paper variant="outlined" className="container" ref={wrapperRef}></Paper>
  )
}
