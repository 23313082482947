export const LEAD_INITIAL_STATE = {
  vrm: "",
  vin: "",
  aid: null,
  mileage: null,
  postCode: null,
  postalTown: null,
  year: null,
  make: "",
  model: "",
  trim: "",
  transmission: "",
  engine: "",
  type: "",
  fuel: "",
  color: "",
  titleStatus: null,
  fbmpId: null,
  otherSource: null,
  listedPrice: null,
  at_retail: null,
  at_private: null,
  at_trade: null,
  at_part_exchange: null,
  sellerName: "",
  sellerPhone: "",
  fbName: "",
  city: "",
  f_image: null,
  images: [],
  battery_range: null,
  battery_capacity: null,
  vehicle_check: {},
  condition: {},
  autoCheck: {},
}
