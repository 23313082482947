import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { MenuItem, Stack } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import useData from "../../context/Data"
import { useParams } from "react-router-dom"
import SelectMenu from "../createPage/SelectMenu"
import PricesInput from "../createPage/PricesInput"

export default function PopUpDialogAccept({
  open,
  setOpen,
  loading,
  setLoading,
  setErr,
  setSuccess,
  isAllowed = true,
  setActivity,
  setLead,
  lead,
}) {
  const { id } = useParams()

  const { dealerships } = useData()

  const [agreedPrice, setAgreedPrice] = React.useState(lead?.prePrice)
  const [dealer, setDealer] = React.useState(null)

  const dealers = React.useMemo(() => {
    return dealerships?.filter((d) => lead?.sentTo?.includes(d?._id))
  }, [dealerships, lead?.sentTo])

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChangeSelect = (value, name) => {
    setDealer(value)
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    if (!isAllowed) {
      setErr("Permissions Not Granted")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!agreedPrice || agreedPrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }

    if (!dealer) {
      setErr("Please Select Dealership")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    const data = {
      agreedPrice,
      dealer,
    }
    setErr(null)
    setLoading(true)
    setSuccess(null)
    try {
      const res = await axios.patch(`${URL}/leads/item/${id}/accept`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          ...res.data.lead,
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setSuccess(true)
      setLoading(false)
      setAgreedPrice(null)
      setDealer(null)
      setOpen(false)
      setTimeout(() => {
        setSuccess(null)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>Accept lead on behalf of Dealership</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <DialogContentText>
            Please Select Dealership & Add the Agreed Price
          </DialogContentText>
          <SelectMenu
            sx={{ minWidth: 150 }}
            label="Dealership"
            options={dealers}
            value={dealer}
            handleChange={handleChangeSelect}
            name={"dealer"}
            size="medium"
          >
            {dealers &&
              dealers?.map((o, i) => (
                <MenuItem key={o?._id + i} value={o?._id}>
                  {o?.dname}
                </MenuItem>
              ))}
          </SelectMenu>
          <Stack direction={"row"} spacing={2}>
            <PricesInput
              lable="Agreed Price"
              value={agreedPrice}
              onValueChange={setAgreedPrice}
              size="medium"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={submitDeal}>
          {loading ? "Submiting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
