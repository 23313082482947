import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import axios from "axios"
import moment from "moment"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { URL } from "../config/apis"
import { useEffect } from "react"
import UsersTable from "../components/dealership/UsersTable"
import PopUpDialogCreateUser from "../components/modals/PopUpDialogCreateUser"
import FeedBackBox from "../components/global/FeedBackBox"
import SimpleDialog from "../components/modals/DialgoSelectUser"

export default function DealershipPage() {
  const { id } = useParams()
  const [dealership, setDealership] = useState(null)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [err, setErr] = useState(null)
  const [selected, setSelected] = useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [show, setShow] = React.useState(false)

  const getDealership = async () => {
    try {
      const res = await axios.get(`${URL}/dealerships/profile/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })

      setDealership(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getDealership()
  }, [])

  if (loading) {
    return <>loading...</>
  }

  if (!loading && !dealership && err) {
    return <>{err}</>
  }

  return (
    <>
      <Paper variant="outlined">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Stack
            width={"100%"}
            alignItems="center"
            direction={"row"}
            spacing={5}
          >
            <Avatar
              sx={{ width: 120, height: 120 }}
              alt={dealership?.dname}
              src={dealership?.logo?.url || "/nologo.png"}
            />
            <Stack minWidth={"40%"} spacing={1}>
              <Typography fontWeight={600} color="#444444" variant="h5">
                {dealership?.dname}
              </Typography>
              <Typography color="#949494" variant="body1">
                {dealership?.address}
              </Typography>
              <Typography color="#949494" variant="body1">
                {dealership?.city} / {dealership?.postCode}
              </Typography>
              <Stack direction={"row"}>
                <Button variant="contained">Edit</Button>
              </Stack>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack spacing={1}>
              <Typography variant="body1">
                Group: <b>{dealership?.organisation?.name}</b>
              </Typography>
              <Typography variant="body1">
                Date Created:{" "}
                {moment(dealership?.createdAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography variant="body1">
                Total Members: {dealership?.users?.length}
              </Typography>
              <Typography variant="body1">
                Done Deals: {dealership?.deals?.length}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <Divider />
      <Grid container columns={3} spacing={5}>
        <Grid item sm={3} md={2}>
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              sx={{ pt: 2 }}
              alignItems="center"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography variant="h6">Users</Typography>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Button onClick={() => setIsOpen(true)} variant="outlined">
                  Select User
                </Button>
                <Button onClick={() => setOpen(true)} variant="contained">
                  New User
                </Button>
              </Stack>
            </Stack>
            <Divider />
            {dealership?.users?.length === 0 ? (
              <Paper>
                <Stack
                  sx={{ py: 3, px: 5 }}
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={2}
                >
                  <Typography color={"gray"} variant="h5">
                    This dealership has no users!
                  </Typography>
                  <Stack
                    alignItems={"center"}
                    justifyContent="center"
                    direction={"row"}
                    spacing={2}
                  >
                    <Button onClick={() => setIsOpen(true)} variant="outlined">
                      Select user
                    </Button>
                    <Typography>Or</Typography>
                    <Button onClick={() => setOpen(true)} variant="contained">
                      Create new
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            ) : (
              <UsersTable
                users={dealership?.users}
                setDealership={setDealership}
                admins={dealership?.admins}
              />
            )}
          </Stack>
        </Grid>
        <Grid item sm={3} md={1}>
          <Stack sx={{ pt: 2 }} spacing={2}>
            <Typography variant="h6">Invoices</Typography>
            <Stack>Coming soon</Stack>
          </Stack>
        </Grid>
      </Grid>
      <PopUpDialogCreateUser
        loading={isLoading}
        setDealership={setDealership}
        setErr={setError}
        setLoading={setIsLoading}
        setShow={setShow}
        id={id}
        open={open}
        setOpen={setOpen}
      />
      <FeedBackBox loading={isLoading} show={show} err={error} />
      <SimpleDialog
        setOpen={setIsOpen}
        open={isOpen}
        dealership={dealership}
        setDealership={setDealership}
      />
    </>
  )
}
