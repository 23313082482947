import { CircularProgress, Stack, Typography } from "@mui/material"
import React from "react"

export default function TableLoadingData() {
  return (
    <Stack
      sx={{
        position: "absolute",
        inset: 0,
        //justifyContent: "center",
        alignItems: "center",
        bgcolor: "#ffffff82",
        py: 15,
      }}
      spacing={2}
    >
      <CircularProgress size={30} />
      <Typography>Loading Data ...</Typography>
    </Stack>
  )
}
