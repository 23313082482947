import { ListItem, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useState, useMemo } from "react"

export default function MyOwnMessage({ message, part }) {
  const [state, setState] = useState({
    hasMedia: message?.type === "media",
    mediafailed: false,
    mediaUrl: null,
  })
  useMemo(() => {
    if (state.hasMedia) {
      message?.media
        .getContentTemporaryUrl()
        .then((url) => setState({ ...state, mediaUrl: url }))
        .catch((e) => setState({ ...state, mediafailed: true }))
    }
  }, [message])

  return (
    <>
      <ListItem sx={{ justifyContent: "end" }}>
        <Stack
          bgcolor={"#444ce7"}
          sx={{
            color: "white",
            maxWidth: "70%",
            float: "right",
            py: 1,
            px: 2,
            borderRadius: "10px 0px 10px 10px",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
          >
            <Typography variant="caption">
              {moment(message?.dateCreated).format("MM/DD/YY h:mm A")}
            </Typography>
            <Typography variant="subtitle2">{"Me"}</Typography>
          </Stack>
          <div>
            <Typography variant="body2">
              <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{ __html: message?.body }} />
            </Typography>
            {state.hasMedia && state.mediaUrl && !state.mediafailed ? (
              <span
                //onClick={() => openImage(state.mediaUrl)}
                className="font-medium cursor-pointer text-gray-600"
              >
                <img width={100} src={state.mediaUrl} />
              </span>
            ) : (
              <>
                {state.hasMedia && state.mediafailed && (
                  <Typography variant="subtitle2">
                    Failed to load media
                  </Typography>
                )}
              </>
            )}
          </div>
        </Stack>
      </ListItem>
    </>
  )
}
