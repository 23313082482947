import { deleteObject, ref } from "firebase/storage"
import { storage } from "../../config/firebase"

//delete single image
export const deleteFromFB = async (path) => {
  const desertRef = ref(storage, path)
  // Delete the file
  try {
    await deleteObject(desertRef)
  } catch (error) {
    console.log(error)
  }
}

//delete one from and array
export const deleteOneFromFB = async (path, name, setData) => {
  const desertRef = ref(storage, path)
  // Delete the file
  try {
    const df = await deleteObject(desertRef)
    setData((prev) => [...prev.filter((i) => i.name !== name)])
  } catch (error) {
    setData((prev) => [...prev.filter((i) => i.name !== name)])
  }
}

//delete one from and array
export const deleteOneFromFBCreate = async (path) => {
  const desertRef = ref(storage, path)
  // Delete the file
  try {
    await deleteObject(desertRef)
  } catch (error) {
    console.log(error)
  }
}
