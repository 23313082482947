import axios from "axios"
import React, { useEffect, useState } from "react"
import CalendarBox from "../components/calendar"
import { URL } from "../config/apis"

export default function CalendarPage() {
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)

  const getAppointments = async () => {
    setErr(null)
    try {
      const res = await axios.get(`${URL}/calendar`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAppointments(res.data)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAppointments()
  }, [])

  if (loading) {
    return <>Loading ...</>
  }
  if (err) {
    return <>{err}</>
  }

  return (
    <div>
      <CalendarBox appointments={appointments} />
    </div>
  )
}
