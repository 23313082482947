import React, { useRef, useState } from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { Button, Stack } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import LinearProgress from "@mui/material/LinearProgress"

import { useParams } from "react-router-dom"
import {
  uploadImagesActivity,
  UploadUrlStringAc,
} from "../../../helppers/images/uploadImages"
import { deleteOneFromFB } from "../../../helppers/images/deleteImages"
import {
  handleDrag,
  handleDragIn,
  handleDragOut,
} from "../../../helppers/functions"
import { URL } from "../../../config/apis"
import axios from "axios"

export default function ActivityInputBox({ setActivity }) {
  const { id } = useParams()

  const [value, setValue] = React.useState("")

  const imagesRef = useRef()
  const [images, setImages] = useState([])
  const [isloading, setIsLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [drag, setDrag] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [dragCounter, setDragCounter] = useState(0)
  const [err, setErr] = useState(null)
  const [show, setShow] = useState(false)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handlePastImage = (event) => {
    const items = (event?.clipboardData || event?.originalEvent?.clipboardData)
      ?.items
    let blob = null
    for (let i = 0; i < items?.length; i++) {
      if (items[i]?.type?.indexOf("image") === 0) {
        blob = items[i]?.getAsFile()
      }
    }
    if (blob !== null) {
      setUploading(true)
      const reader = new FileReader()
      reader.onload = (event) => {
        UploadUrlStringAc(
          event.target.result,
          setImages,
          setUploading,
          "activity"
        )
      }
      reader.readAsDataURL(blob)
    }
  }

  const DeleteImage = (path, name) => {
    deleteOneFromFB(path, name, setImages)
  }

  const handlUploadImages = async (files) => {
    await uploadImagesActivity(files, setUploading, setImages, "activity")
    if (imagesRef.current != null) {
      imagesRef.current.FileList = []
      imagesRef.current.value = ""
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDrag(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handlUploadImages(e.dataTransfer.files)
      setDragCounter(0)
    }
  }

  const createNewActivity = async (e) => {
    e.preventDefault()
    if (value?.trim() === "" && images?.length === 0) return
    setErr(null)
    setShow(false)
    setIsLoading(true)
    const data = {
      comment: value,
      images,
    }
    try {
      const res = await axios.post(`${URL}/activity/lead/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setActivity((prev) => [res.data?.activity, ...prev])
      setImages([])
      setValue("")
      setIsLoading(false)
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 2000)
    } catch (error) {
      setIsLoading(false)
      setErr(error.response?.data?.message)
    }
  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={createNewActivity}
    >
      <div
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onDragEnter={(e) => handleDragIn(e, setDragCounter, setDrag)}
        onDragLeave={(e) => handleDragOut(e, setDragCounter, setDrag)}
        onPaste={handlePastImage}
        style={{ position: "relative" }}
      >
        <Stack
          sx={{
            position: "absolute",
            inset: 0,
            bgcolor: "#fff",
            border: "2px dashed #ccc",
            coloe: "#ccc",
          }}
          justifyContent="center"
          alignItems="center"
          zIndex={2}
          display={drag ? "flex" : "none"}
        >
          Drop here
        </Stack>
        {isloading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <TextField
          id="outlined-textarea"
          placeholder="Add new activity note..."
          multiline
          hiddenLabel
          value={value}
          onChange={handleChange}
        />
        {uploading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Stack direction="row" sx={{ paddingY: "10px" }} spacing={2}>
          <div
            style={{ flexGrow: 1, flexWrap: "wrap", display: "flex", gap: 2 }}
          >
            {images &&
              images?.map((image, i) => (
                <div
                  key={image?.name + i}
                  style={{
                    position: "relative",
                    height: "80px",
                    width: "80px",
                  }}
                >
                  <img
                    src={image?.url}
                    alt={image?.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "#0000009e",
                    }}
                    color="primary"
                    component="label"
                    onClick={() => DeleteImage(image?.path, image?.name)}
                  >
                    <DeleteForeverIcon
                      fontSize="small"
                      sx={{ color: "#fff" }}
                    />
                  </IconButton>
                </div>
              ))}
          </div>
          <Stack
            direction="row"
            sx={{ flex: "none", height: "max-content !important" }}
            spacing={2}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                ref={imagesRef}
                onChange={(e) => handlUploadImages(e.target.files)}
                multiple
                hidden
                accept="image/*"
                type="file"
              />
              <PhotoCamera />
            </IconButton>
            <Button disabled={isloading} type="submit" variant="contained">
              Send
            </Button>
          </Stack>
        </Stack>
      </div>
    </Box>
  )
}
