import { Navigate, Outlet, useLocation } from "react-router-dom"
import useAuth from "../../context/Auth"
import { DataContextProvider } from "../../context/Data"
import { TwilioChatContextProvider } from "../../context/TwilioChat"
import MainLayout from "./MainLayout"

export const PrivateRoutes = () => {
  const { user } = useAuth()
  const location = useLocation()

  return user ? (
    <TwilioChatContextProvider>
      <DataContextProvider>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </DataContextProvider>
    </TwilioChatContextProvider>
  ) : (
    <Navigate to="/login" state={location} />
  )
}
