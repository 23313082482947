import * as React from "react"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import AppointmentLine from "./AppointmentLine"

export default function AppointmentBox({
  appointmnets,
  setAppointments,
  setActivity,
}) {
  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {appointmnets?.map((app, i) => (
          <>
            <AppointmentLine
              key={app?._id + i}
              app={app}
              setAppointments={setAppointments}
              setActivity={setActivity}
            />
            {appointmnets?.length - 1 !== i && (
              <Divider key={app?._id + i} variant="inset" component="li" />
            )}{" "}
          </>
        ))}
      </List>
    </>
  )
}
