import React from "react"
import { Divider, Grid, List } from "@mui/material"
import AppointmentsBox from "./AppointmentsBox"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import { DashboardContext } from "../../pages/Dashboard"
import ListItems from "./ListItems"
import ListSkeleton from "./ListSkeleton"

export default function UpcomingAppointmnets() {
  const { upcoming, loading } = React.useContext(DashboardContext)
  const [page, setPage] = React.useState(1)

  const limit = 5
  let startIdx = (page - 1) * limit

  const movePge = (val) => {
    if (val === -1) {
      setPage((prev) => {
        if (prev === 1) {
          return
        }
        return prev + val
      })
    } else {
      setPage((prev) => {
        if (prev === total) {
          return
        }
        return prev + val
      })
    }
  }

  const total = React.useMemo(() => {
    return Math.ceil(upcoming?.length / limit)
  }, [upcoming, limit])

  const activeArr = React.useMemo(() => {
    startIdx = (page - 1) * limit
    return upcoming?.slice(startIdx, startIdx + limit)
  }, [upcoming, limit, page])

  return (
    <Grid item sm={4}>
      <AppointmentsBox
        label={"Upcoming Appointments - " + upcoming?.length}
        movePge={movePge}
        page={page}
        total={total}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            maxHeight: 381,
          }}
        >
          {loading ? (
            [...Array(5)].map((_, i) => <ListSkeleton key={"ske-" + i} />)
          ) : (
            <>
              {activeArr.map((item, i) => (
                <>
                  <ListItems
                    item={item}
                    key={"list-" + i + item?._id}
                    Icon={InsertInvitationIcon}
                    color="#42a5f5"
                  />
                  {i !== 4 && (
                    <Divider
                      key={item?._id + "divider" + i}
                      sx={{ marginLeft: 0 }}
                      variant="inset"
                      component="li"
                    />
                  )}
                </>
              ))}
            </>
          )}
        </List>
      </AppointmentsBox>
    </Grid>
  )
}
