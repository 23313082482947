import PhotoCamera from "@mui/icons-material/PhotoCamera"
import {
  Button,
  Divider,
  IconButton,
  List,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import useAuth from "../../context/Auth"
import useTwilioChat from "../../context/TwilioChat"
import IncomeMessage from "./IncomeMessage"
import MyOwnMessage from "./MyOwnMessage"

const ChatBox = React.forwardRef(
  ({ conv, messages, setMessages, deleteConv, parts }, ref) => {
    const { user } = useAuth()

    const { setunreadCount } = useTwilioChat()
    const [textSms, setTextSms] = useState("")

    //   const title = useMemo(() => {
    //     return conv?.friendlyName?.split(" - ")[0]
    //   }, [conv?.friendlyName])

    const onSendMessage = async () => {
      if (textSms?.trim() === "") return null
      try {
        await conv?.sendMessage(String(textSms).trim())
        setTextSms("")
        const v = await conv?.setAllMessagesRead()
        setunreadCount((prev) => [...prev?.filter((c) => c?.id !== conv?.sid)])
      } catch (error) {
        console.log(error)
      }
    }

    const sendMessageImage = async (file) => {
      if (!file) return
      await conv?.sendMessage({ contentType: file.type, media: file })
      const v = await conv?.setAllMessagesRead()
      setunreadCount((prev) => [...prev?.filter((c) => c?.id !== conv?.sid)])
    }

    const ontextChange = (e) => {
      const type = e.nativeEvent?.inputType
      const val = e.target?.value
      if (type === "insertLineBreak") {
        setTextSms((prev) => `${prev}\n`)
      } else {
        setTextSms(val)
      }
    }
    
    return (
      <Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          py={1}
          px={2}
        >
          <Typography
            title={conv?.friendlyName}
            variant="body1"
            fontWeight={600}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {conv?.friendlyName}
          </Typography>
          {/* <Stack className="flex items-center space-x-2">
            <button
              onClick={deleteConv}
              className="bg-red-600 text-white px-3 py-1 rounded-md"
            >
              Delete
            </button>
          </Stack> */}
        </Stack>
        <Divider />
        <List
          ref={ref}
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            pt: 1,
            pb: 3,
            height: 400,
            "& ul": { padding: 0 },
            overflowY: "auto",
            scrollBehavior: "smooth",
          }}
          className="messages"
        >
          {messages &&
            messages?.map((message) =>
              message?.conversation?.sid === conv?.sid &&
              message?.author === user?.email ? (
                <MyOwnMessage
                  key={message?.sid}
                  message={message}
                  part={parts?.find((p) => p?.sid === message?.participantSid)}
                />
              ) : message?.conversation?.sid === conv?.sid &&
                message?.author !== user?.email ? (
                <IncomeMessage
                  key={message.sid}
                  message={message}
                  part={parts?.find((p) => p?.sid === message?.participantSid)}
                />
              ) : null
            )}
          <div></div>
        </List>
        <Divider />
        <Stack direction={"row"} alignItems="center">
          <TextField
            id="chat"
            placeholder="Write Something"
            multiline
            maxRows={2}
            value={textSms}
            onChange={ontextChange}
            sx={{ bgcolor: "transparent" }}
            fullWidth
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              name="imageTwilio"
              id="imageTwilio"
              onChange={(e) => sendMessageImage(e.target.files[0])}
            />
            <PhotoCamera />
          </IconButton>

          <Button onClick={onSendMessage} variant="contained">
            Send
          </Button>
        </Stack>
      </Stack>
    )
  }
)
export default ChatBox
