import {
  Alert,
  Button,
  Container,
  CssBaseline,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { URL } from "../config/apis"
import useAuth from "../context/Auth"

export default function LoginPage() {
  const { setLoading } = useAuth()
  const { state } = useLocation()

  const [err, setErr] = useState(null)
  const [isLoding, setIsLoading] = useState(false)

  const login = async (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form.entries())
    setIsLoading(true)
    try {
      const res = await axios.post(`${URL}/auth/login`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setIsLoading(false)
      setLoading(true)
      setTimeout(() => {
        if (state?.pathname) {
          window.location.replace(state?.pathname)
        } else {
          window.location.replace("/")
        }
      }, 1000)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xs"
        sx={{
          width: "100%",
          //  flexDirection: "column",
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper sx={{ width: "100%" }}>
          <Stack
            component="form"
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "white",
              py: 10,
              px: 5,
            }}
            alignItems="center"
            justifyContent={"center"}
            spacing={2}
            noValidate
            autoComplete="off"
            onSubmit={login}
          >
            {err && <Alert severity="error">{err}</Alert>}

            <Typography fontWeight={700} variant="h5">
              Login to Control Panel
            </Typography>
            <TextField
              size="small"
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              //type="email"
            />
            <TextField
              size="small"
              id="password"
              label="password"
              variant="outlined"
              fullWidth
              name="password"
              type="password"
            />
            <Button
              fullWidth
              color="success"
              variant="contained"
              type="submit"
              disabled={isLoding}
            >
              {isLoding ? "submitting" : "login"}
            </Button>
          </Stack>
        </Paper>
      </Container>
    </React.Fragment>
  )
}
