import moment from "moment"

export const formatPrice = (price) => {
  if (!price) return "N/A"
  if (isNaN(price)) return "N/A"
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
  })
  return formatter.format(price)
}

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY")
}

export const doHtmlDisplay = (text, limit = 50) => {
  if (text?.length > limit) {
    return text?.substring(0, limit) + " ..."
  }
  return text
}
