import { Stack } from "@mui/material"
import React from "react"
import Grid from "@mui/material/Grid"
import TopTitleLeftSide from "./TopTitleLeftSide"
import TopTileRightSide from "./TopTileRightSide"

export default function TopTitleSide({ lead,setIsOpen }) {
  return (
    <>
      <Stack
        sx={{
          paddingX: "20px",
          paddingY: "10px",
          display: "flex",
          width: "100%",
        }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Grid container spacing={2}>
          <Grid item sm={10}>
            <TopTitleLeftSide lead={lead} setIsOpen={setIsOpen} />
          </Grid>
          <Grid item sm={2}>
            <TopTileRightSide lead={lead} />
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}
