import { Grid, Stack } from "@mui/material"
import React from "react"
import { formatPrice } from "../../helppers/formatters"
import DataLine from "./DataLine"

export default function LeadInfo({ lead }) {
  return (
    <Stack
      sx={{ paddingX: "20px", paddingY: "10px" }}
      direction={"column"}
      // spacing={2}
    >
      <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
        <DataLine title="Seller Name" value={lead?.sellerName || "N/A"} />
        <DataLine title="Seller Phone" value={lead?.sellerPhone || "N/A"} />
        <DataLine title="City" value={`${lead?.city || "N/A"} `} />
        <DataLine title="Post Code" value={lead?.postCode || "N/A"} />
        {/* <DataLine title="Title Status" value={lead?.titleStatus || "N/A"} /> */}
        <DataLine title="Postal Town" value={lead?.postalTown || "N/A"} />
        <DataLine title="Vehicle Type" value={lead?.type || "N/A"} />
      </Grid>
      <br />
      <Grid container spacing={2} sx={{ paddingBottom: "10px" }}>
        <DataLine title="Fuel" value={lead?.fuel || "N/A"} />
        <DataLine title="Color" value={lead?.color || "N/A"} />
        <DataLine title="Previous Owners" value={lead?.prevOwners || "N/A"} />
        <DataLine title="Service history" value={lead?.sHistory || "N/A"} />
        <DataLine
          tooltip="AutoTrader Recommended Retail"
          title="AutoTrader RR"
          value={formatPrice(lead?.at_retail)}
        />
        <DataLine title="MAX offer" value={formatPrice(lead?.weBuyAnyCar)} />
        {/*<DataLine title="Trade" value={formatPrice(lead?.at_trade)} />
        <DataLine
          title="Part Exchange"
          value={formatPrice(lead?.at_part_exchange)}
        /> */}
      </Grid>
    </Stack>
  )
}
