import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect } from "react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import AgentCard from "../components/agent/AgentCard"
import PopUpDialogCreateAgent from "../components/modals/PopUpDialogCreateAgent"
import PopUpDialogCreateOrg from "../components/modals/PopUpDialogCreateOrg"
import { URL } from "../config/apis"

export default function AgentsPage() {
  const navigate = useNavigate()

  const [agents, setAgents] = useState([])
  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  const getDealerships = async () => {
    setFetching(true)
    try {
      const res = await axios.get(`${URL}/agents`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAgents(res.data)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getDealerships()
  }, [])

  if (loading) {
    return <>Loading...</>
  }

  if (!loading && err) {
    return <>{err}</>
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box>
          <Stack
            direction={"row"}
            spacing={5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Users</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: 5,
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="primary"
              >
                New User
              </Button>
            </div>
          </Stack>
          {agents?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                We don't have any Agents
              </Typography>
              <Stack
                alignItems={"center"}
                justifyContent="center"
                direction={"row"}
                spacing={2}
              >
                <Button onClick={() => setOpen(true)} variant="contained">
                  Create New User
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Grid py={3} container spacing={3}>
              {agents?.map((d, i) => (
                <Grid key={d?._id + i} xs={6} md={4} xl={3} item>
                  <AgentCard agent={d} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
      <PopUpDialogCreateAgent
        setErr={setError}
        loading={isLoading}
        open={open}
        setLoading={setIsLoading}
        setOpen={setOpen}
        setShow={setShow}
        setState={setAgents}
      />
    </>
  )
}
