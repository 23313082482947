import { Paper } from "@mui/material"

export default function PaperContainer({
  children,
  minHeight = "500px",
  variant = "elevation",
  py = "10px",
}) {
  return (
    <Paper
      variant={variant}
      elevation={(variant = "elevation" ? 1 : 0)}
      sx={{ paddingY: py, minHeight }}
    >
      {children}
    </Paper>
  )
}
